import { FC, Fragment } from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import DataCompute from "./canvas/main";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import "../index.css";
import "../App.css";

const App = ()=>{
    const client = new ApolloClient({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
      cache: new InMemoryCache(),
    });

    return (
        <Fragment>
          <ApolloProvider client={client}>
            <Provider store={store}>
              {/* <React.StrictMode> */}
                <DataCompute />
              {/* </React.StrictMode> */}
            </Provider>
          </ApolloProvider>
        </Fragment>
    )
}

export default App;