import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { formLayerState } from "./form-state-reducers.types";
import { layerBasedOnEntTypeName } from "../fcs";
import { EntityTypeNames } from "../canvas/entity-objects.types";

export const setFormState = createAction("setFormState", function prepare(layer:number,entityId:string,entityTypeName:string,additionalParam1?:string){
    return {
        payload: {
            layer,
            entityId,
            entityTypeName,
            additionalParam1,
        }
    }
});

export const resetFormState = createAction("createFormState", function prepare(){
    return {payload:null};
});

const visitedFlag = localStorage.getItem("ddcmFlag");

const defaultState = {
    0:{entityId:"",entityTypeName:"",additionalParam1:""},
    1:{entityId:"",entityTypeName:"",additionalParam1:""},
    2:{entityId:"",entityTypeName:"",additionalParam1:""},
    3:{entityId:!visitedFlag ? "docs" : "", entityTypeName:EntityTypeNames.docs, additionalParam1:""},
    4:{entityId:"",entityTypeName:"",additionalParam1:""},
}

export const formStateReducer = createReducer<formLayerState>(defaultState, (builder) => {
    builder.addCase(resetFormState, (state, action)=>{
        return defaultState;
    })
    .addCase(setFormState, (state:formLayerState, action) => {
        state[action.payload.layer].entityId = action.payload.entityId;
        state[action.payload.layer].entityTypeName = action.payload.entityTypeName;
        state[action.payload.layer].additionalParam1 = action.payload?.additionalParam1 || "";
    })
});