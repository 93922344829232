import { FC, Fragment, useContext, useState } from "react";
import { Execute, ExecOrder } from "../taskbar-buttons";
import { GeneralContext, GeneralContextType } from "./main";
import { store } from "../redux/store";
import ExecutionProgress from "./exec-progress";
import ExecutionOrder from "../fcs/execution-reorder";
import { setFormState } from "../redux/form-state-reducers";
import { EntityTypeNames } from "./entity-objects.types";
import { layerBasedOnEntTypeName } from "../fcs";
import { useDispatch } from "react-redux";

import { ParamOutput } from "../utilities/compute-helper.types";

const Navbar:FC = ()=>{
    const {computeHelper} = useContext(GeneralContext) as GeneralContextType;
    const [paramOutput, setParamOutput] = useState<ParamOutput|undefined>(undefined);

    const [toggleExecOrder, setToggleExecOrder] = useState<boolean>(false);
    const dispatch = useDispatch();
    return (
        <Fragment>
        <nav className="navbar">
            <div id="app-title">
                Dynamic Data Compute Management<br/>
                <span id="app-version">Ver. 0.1.0-demo</span>
            </div>
            <div className="exec-ctrl">
                <Execute title={"Execute all computes"} additionalClass="" onClick={()=>{
                    const paramOutput = computeHelper.prepareExecParams(store.getState());
                    if(paramOutput) setParamOutput(paramOutput);
                }}/>
                <ExecOrder title={"Execution Order Setup"} additionalClass="" onClick={()=>{
                    setToggleExecOrder(true);
                }}/>
            </div>
            <div className="docs btn" title="Documentation" onClick={()=>{
                dispatch(setFormState(layerBasedOnEntTypeName.docs, "docs", EntityTypeNames.docs));
            }}>
                <i className="fa-solid fa-book"/>
                <span>Docs</span>
            </div>
        </nav>
        {paramOutput !== undefined && <ExecutionProgress paramOutput={paramOutput} setParamOutput={setParamOutput}/>}
        {toggleExecOrder && <div className="canvas-modal">
            <ExecutionOrder setDisplay={setToggleExecOrder}/>
        </div>}
        </Fragment>
    )
}

export default Navbar