import { configureStore, combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";
// import { GetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import {canvasReducer, connectorReducer} from "./canvas-reducers";
import { dataReducer } from "./data-reducers";
import { formStateReducer } from "./form-state-reducers";
import { selectReducers } from "./dataset-selector-reducers";
import { fieldReducer } from "./field-reducers";
import { referenceReducer } from "./reference-reducers";
import { externalVarReducer } from "./external-data-reducers";
import { computeReducer } from "./compute-reducers";
import { messageReducer } from "./message-reducer";

const rootReducer = combineReducers({
    canvas:canvasReducer,
    data:dataReducer,
    forms:formStateReducer,
    connectors:connectorReducer,
    selectElements:selectReducers,
    fields:fieldReducer,
    references:referenceReducer,
    externalVar:externalVarReducer,
    computes:computeReducer,
    message: messageReducer,
});

export const store = configureStore({ 
  reducer: rootReducer, 
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), 
  devTools: process.env.NODE_ENV !== 'production' && {
    name: 'MyApp',
    trace: true,
    traceLimit: 25,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;