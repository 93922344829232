import { createSlice } from "@reduxjs/toolkit";

import { ComputeSliceState, SaveCodeProps, SaveResultProps,
    SetNewSortProps,
 } from "./compute-reducers.types";

const initialState:{[key:string]:ComputeSliceState} = {};

const computeSlice = createSlice({
    name:"computes",
    initialState,
    reducers:{
        createComputeState: (state, action)=>{
            state = action.payload as {[key:string]:ComputeSliceState};
            return state;
        },
        saveCode:(state,action)=>{
            const {entityId,fieldName,code,returnType} = action.payload as SaveCodeProps;
            if(!state?.[entityId]) state[entityId] = {};
            if(!state?.[entityId]?.[fieldName]){
                const sortIndex = Object.values(state).map((subprops)=>{
                    return Object.keys(subprops).map((item)=>1) || [];
                }).flat().length;
                state[entityId][fieldName] = {code, returnType, sortIndex, result:{}};
            }else{
                state[entityId][fieldName].code = code;
                state[entityId][fieldName].returnType = returnType;
            }
            return state;
        },
        saveResult:(state,action)=>{
            const {entityId, fieldName,result} = action.payload as SaveResultProps;
            state[entityId][fieldName].result = result;
            return state;
        },
        pushResult:(state,action)=>{
            const {entityId, fieldName,result} = action.payload as SaveResultProps;
            const currentResult = state[entityId][fieldName].result || {};
            state[entityId][fieldName].result = {...currentResult, ...result};
            return state;
        },
        setNewExecSort:(state,action)=>{
            const {entityId, fieldName, newIndex} = action.payload as SetNewSortProps;
            const oldIndex:number = state[entityId]?.[fieldName]?.sortIndex || 0;
            for(const [entityId, item] of Object.entries(state)){
                for(const [fieldNameKey,fieldProps] of Object.entries(item)){
                    if(fieldProps.sortIndex <= newIndex && fieldProps.sortIndex > oldIndex) fieldProps.sortIndex -= 1;
                    if(fieldProps.sortIndex >= newIndex && fieldProps.sortIndex < oldIndex) fieldProps.sortIndex += 1;
                    if(fieldNameKey === fieldName) fieldProps.sortIndex = newIndex;
                }
            }
            return state;
        },
        changeComputeFieldName:(state,action)=>{
            const {entityId, oldFieldName, newFieldName} = action.payload as {entityId:string, oldFieldName:string, newFieldName:string};
            const nameFlag = state?.[entityId]?.[oldFieldName] ? true : false;
            if(nameFlag){
                state[entityId][newFieldName] = {...state[entityId][oldFieldName]};
                delete state[entityId][oldFieldName];
            }
        },
        deleteCompute: (state,action)=>{
            const entityId = action.payload as string;
            delete state?.[entityId];
        }
    }
});

export const {saveCode, saveResult, pushResult, setNewExecSort, deleteCompute, changeComputeFieldName, createComputeState} = computeSlice.actions;

export const computeReducer = computeSlice.reducer;