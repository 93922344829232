import { FC, useState, useCallback, useRef, useMemo, Fragment, ReactElement, useContext } from "react";
import { Retrieve, Execute, Setup, Edit } from "../taskbar-buttons";
import { RootState, AppDispatch } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { getCssVariable } from "../utilities/canvas-helper";
import { GeneralContext, GeneralContextType } from "../canvas/main";
import Modal from "../canvas/modal";
import ColumnReorder from "./column-reorder";
import { layerBasedOnEntTypeName, Loader } from ".";

import { EntityTypeNames, ElementPos } from "../canvas/entity-objects.types";
import { SortedField } from "./column-reorder.types";
import { CodeProps } from "./code-editor.types";
import { DatasetProps } from "./table.types";
import { ComputeSliceState } from "../redux/compute-reducers.types";
import { DataReducerState, PagingProps, Data } from "../redux/data-reducers.types";
import { setFormState } from "../redux/form-state-reducers";

export const ColHeader:FC<CodeProps&{fieldName:string, colMinWidth:number, isCompute?:boolean}> = ({entityId,entityName,fieldName,colMinWidth,isCompute})=>{
    const [isShowFilter,setIsShowFilter] = useState(false);
    const resizer = useRef<ElementPos>(null);
    const th = useRef<HTMLTableCellElement>(null);
    const dispatch = useDispatch();

    const mouseMove = useCallback((e:MouseEvent)=>{
        const minWidth = colMinWidth;
        if(!resizer.current || !resizer?.current?.devX || !th.current) return;
        const newX = e.clientX + resizer.current.devX;
        const width = (newX - th.current.offsetLeft) - 10;
        if(width < minWidth) return;
        th.current.style.width = width + "px";
    },[colMinWidth]);

    const mouseUp = useCallback((e:MouseEvent)=>{
        if(!th.current) return;
        th.current.style.cursor = "initial";
        document.removeEventListener("mousemove",mouseMove);
        document.removeEventListener("mouseup",mouseUp);
    },[mouseMove])

    const mouseDown = useCallback((e:MouseEvent | React.MouseEvent)=>{
        if(!resizer.current || !th.current) return;
        resizer.current.devX = (th.current.offsetLeft + th.current.offsetWidth) - e.clientX;
        document.addEventListener("mousemove",mouseMove);
        document.addEventListener("mouseup",mouseUp);
        th.current.style.cursor = "col-resize";
    },[mouseMove,mouseUp]);

    return (
        <th ref={th}>
            {/* <Filter title="filter" additionalClass={`filter-btn ${isShowFilter?"filter-show":""}`} onClick={()=>{setIsShowFilter((prevState)=>!prevState)}}/> */}
            {isCompute ? <Edit title="Edit Compute Script" additionalClass={`header-btn`} 
            onClick={()=>{dispatch(setFormState(layerBasedOnEntTypeName.dataset_compute,entityId,EntityTypeNames.datasetCompute,fieldName))}}/> : null}
            {fieldName}
            <div className="column-resize" ref={resizer} onMouseDown={mouseDown}></div>
            {/* <div className={`filter-input ${isShowFilter?"":"fi-hidden"}`}>
                <input type="text"></input>
                <button id="submit-filter">Submit Filter</button>
                <button id="reset-filter">Reset Filter</button>
            </div> */}
        </th>
    )
}

export const Headers:FC<CodeProps&{sortedFields:SortedField[]}> = ({entityId,entityName, sortedFields=[]})=>{
    const colMinWidth = useMemo(()=>{
        return Number((getCssVariable("--col-min-width") || "100").replace("px",""));
    },[]);

    return (
        <Fragment>
            <thead>
                <tr>
                    {sortedFields.map((item, i)=>{
                        if(!item.isDisplay) return null;
                        return (
                            <ColHeader key={i} entityId={entityId} isCompute={item.isCompute || false} entityName={entityName} fieldName={item.fieldName} colMinWidth={colMinWidth}/>
                        )
                    })}
                </tr>
            </thead>
        </Fragment>
    )
}

export const TableRows:FC<CodeProps&{sortedFields:SortedField[], data:Data[]|undefined}> = ({entityId,entityName,sortedFields=[],data})=>{
    const computeData = useSelector<RootState>((state)=>state.computes?.[entityId]) as ComputeSliceState;

    const pageSize = Number(process.env.REACT_APP_PAGINATION_SIZE || 50);

    const primaryFields = useMemo(()=>{
        return sortedFields.filter((item)=>item.isPrimaryColumn).map((item)=>item.fieldName);
    },[sortedFields]);

    const renderColumn = useCallback((rowData:Data, parentKey:string="")=>{
        let tds:ReactElement[] = [];
        let idValue = primaryFields.map((fieldName)=>rowData[fieldName].toString()).join("");
        
        for(const item of sortedFields){
            if(!item.isDisplay) continue;
            let value;
            if(item.isCompute){
                value = computeData?.[item.fieldName]?.result?.[idValue] || "";
            }else{
                value = rowData[item.fieldName];
            }
            
            const style:React.CSSProperties = {};

            if(typeof value === "string"){
                if(isNaN(parseFloat(value))){
                    style.textAlign = "left";
                }else{
                    style.textAlign = "right";
                }
                
            }else{
                style.textAlign = "center";
            }
            
            tds.push(
                <td key={parentKey + item.fieldName} style={style}>{value}</td>
            )
        }
        return tds;
    },[sortedFields, primaryFields, computeData]);

    return (
        <Fragment>
            {data !== undefined && data.map((row:Data,i:number)=>(
                <tr key={i}>
                    {renderColumn(row)}
                </tr>
            ))}
        </Fragment>
    )
}

export const Paging:FC<CodeProps&{retrieve:Function, pageProps: PagingProps}> = ({entityId,entityName,retrieve, pageProps})=>{
    const dispatch:AppDispatch = useDispatch();
    const [page,setPageInput] = useState<number>(pageProps?.pageIndex || 1);
    const {messageHelper} = useContext(GeneralContext) as GeneralContextType;

    return (
        <div className="paging">
            <span>{`page: ${pageProps.pageIndex} of ${pageProps.totalPage}${pageProps.totalPage > 1?" / Go to Page:":""}`}</span>
            {pageProps.totalPage > 1 && <div>
                <input type="number" value={page} onChange={(e)=>{setPageInput(Number(e.target.value))}} />
                <button type="button" onClick={async ()=>{
                    if(page===undefined){
                        messageHelper.warning("Please type in page index first");
                        return;
                    }
                    if(page > pageProps.totalPage || page < 1){
                        messageHelper.warning("Table page not found");
                        return;
                    }
                    // dispatch(setPage({entityId, page: page - 1}));
                    await retrieve(page);
                }}>Go</button>
            </div>}
        </div>
    )
}

const Table:FC<DatasetProps> = ({entityId,entityName,width,height, retrieve})=>{
    const {entityHelper} = useContext(GeneralContext) as GeneralContextType;
    const fields:SortedField[] = useSelector(entityHelper.sortedFieldSelector(entityId));
    const {loading, data, error, paging} = useSelector<RootState>((state)=>state.data[entityId]) as DataReducerState;

    const columnSetup = useCallback(()=>{
        entityHelper.setFormState(layerBasedOnEntTypeName[EntityTypeNames.columnReorderDataset], entityId, EntityTypeNames.columnReorderDataset);
    },[entityHelper, entityId]);

    return(
        <div className="table-fc">
            <div className="table-toolbar">
                <Retrieve title="Retrieve data" additionalClass="table-toolbar-btn" onClick={()=>{
                    retrieve(paging?.pageIndex || 1);
                }}/>
                <Execute title="Execute Computed Fields" additionalClass="table-toolbar-btn" />
                <Setup title="Column Setup" additionalClass="table-toolbar-btn" onClick={columnSetup}/>
                <Paging entityId={entityId} entityName={entityName} retrieve={retrieve} pageProps={paging}/>
            </div>
            
            <div className="table-container">
                <table>
                    <Headers entityId={entityId} sortedFields={fields}/>
                    <tbody>
                        <TableRows entityId={entityId} sortedFields={fields} data={data}/>
                    </tbody>
                </table>
            </div>
            {loading && <Loader />}
        </div>
    );
}

export const ColumnSetup:FC<CodeProps&{layer: number, setFormState:Function}> = ({entityId, layer, setFormState})=>{
    const entityName = useSelector<RootState>((state)=>{
        return state.canvas?.[entityId].entityName;
    }) as string;

    return (
        <Modal entityName={entityName || ""} setDisplay={()=>{
            setFormState(layer,"","");
        }}>
            <ColumnReorder entityId={entityId} entityName={entityName} />
        </Modal>
    )
}

export default Table;