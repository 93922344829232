import { FC, useState, useCallback, Fragment } from "react";
import { Add, Edit, Delete } from "../taskbar-buttons";
import { ColHeader } from "./table";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ExtVarEditModal } from "../canvas/modal";
import { addexternalVarItem, updateexternalVarItem } from "../redux/external-data-reducers";
import { useDispatch } from "react-redux";

import { CodeProps } from "./code-editor.types";
import { ExternalVarState, EditExternalVarType } from "../redux/external-data-reducers.types";

export const ExtVarTableRows:FC<CodeProps&{setVarProps:React.Dispatch<React.SetStateAction<EditExternalVarType>>,
                                 setIsShowEditModal:React.Dispatch<React.SetStateAction<boolean>>}> = ({entityId,entityName, setVarProps,setIsShowEditModal})=>{
    const data = useSelector<RootState>((state)=>{
        return state.externalVar?.[entityId];
    }) as ExternalVarState[];

    const onClick = useCallback((index:number,varName:string, value:string|number)=>{
        setVarProps({
            entityId,
            index,
            item:{varName, value}
        })
        setIsShowEditModal(true);
    },[entityId]);

    return (
        <Fragment>
            {(data || []).map((item,i:number)=>(
                <tr key={i}>
                    <td key={1} style={{textAlign:"center"}}>
                        <Edit title="Edit Variable" additionalClass="table-row-button" onClick={()=>{
                            onClick(i,item.varName, item.value);
                        }}/>
                        <Delete title="Delete Variable" additionalClass="table-row-button"/>
                    </td>
                    <td key={2} style={{textAlign:"center"}}>{i + 1}</td>
                    <td key={3}>{item.varName}</td>
                    <td key={4} style={{textAlign:"center"}}>{item.value}</td>
                </tr>
            ))}
        </Fragment>
    )
}

const ExternalVarTable:FC<CodeProps> = ({entityId,entityName})=>{
    const [isShowEditModal,setIsShowEditModal] = useState<boolean>(false);
    const [varProps, setVarProps] = useState<EditExternalVarType>({entityId});
    const dispatch = useDispatch();

    const submitVar = useCallback((params:EditExternalVarType)=>{
        if(params.index===undefined){
            dispatch(addexternalVarItem(params));
        }else{
            dispatch(updateexternalVarItem(params));
        }
    },[dispatch]);

    return(
        <div className="table-fc">
            <div className="table-toolbar">
                <Add title="Add new variable" additionalClass="table-toolbar-btn" onClick={()=>{
                    setVarProps({entityId})
                    setIsShowEditModal(true)
                }}/>
            </div>
            
            <div className="table-container">
                <table>
                    <colgroup>
                        <col style={{width:"35px"}}/>
                        <col style={{width:"35px"}}/>
                        <col/>
                        <col/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th style={{width:"75px"}}>Actions</th>
                            <th style={{width:"75px"}}>No.</th>
                            <ColHeader key={1} entityId={entityId} fieldName={"Variable Name"} colMinWidth={150}/>
                            <ColHeader key={2} entityId={entityId} fieldName={"Value"} colMinWidth={150}/>
                        </tr>
                    </thead>
                    <tbody>
                        <ExtVarTableRows entityId={entityId} setVarProps={setVarProps} setIsShowEditModal={setIsShowEditModal} />
                    </tbody>
                </table>
            </div>
            {isShowEditModal && <ExtVarEditModal data={varProps} setDisplay={setIsShowEditModal} submitVar={submitVar}/>}
        </div>
    );
}

export default ExternalVarTable;