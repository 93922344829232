export const enum ItemOrConnector{
    none,
    item,
    connector,
}

export type EntitySpecsType = {
    entityTypeName:EntityTypeNames,
    imgName:string, 
    text:string,
    faClass:string,
}

export const enum EntityTypeNames{
    compute="compute",
    datasetCompute="dataset_compute",
    dataset="dataset",
    csv="csv",
    externalVariable="external_variable",
    docs="docs",
    //join="join",
    connector="connector",
    columnReorderDataset="column_reorder_dataset",
    columnReorder="column_reorder",
}

export interface ElementPos extends HTMLDivElement{
    startX?:number;
    startY?:number;
    newX?:number;
    newY?:number;
    foundIndex?:number;
    devX?:number;
    currX?:number;
    currY?:number;
    domRect?:DOMRect;
}