import { FC, useState, useCallback } from "react";
import { DefaultModalProps } from "./modal.types";
import { EditExternalVarType } from "../redux/external-data-reducers.types";

const Modal:FC<DefaultModalProps> = ({entityName,setDisplay,children})=>{
    return (
        <div id={`modal-${entityName}`} className="canvas-modal">
            <div className="modal-body">
                <i id="close" title={"Close"} className="fa-solid fa-x taskbar-btn" onClick={setDisplay}></i>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    )
}

type SingleFieldModalProps = {
    labelText:string,
    setValue:React.Dispatch<React.SetStateAction<any>>,
    setDisplay:React.Dispatch<React.SetStateAction<boolean>>
}

export const SingleFieldModal:FC<SingleFieldModalProps> = ({labelText,setValue,setDisplay})=>{
    const [newValue, setNewValue] = useState<string>('');

    const handleSubmit = useCallback((event: React.FormEvent) => {
        event.preventDefault();
        setValue(newValue);
        // setDisplay(false);
    }, [setValue, newValue]);

    const handleSubmitOnEnterKey = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            setValue(newValue);
            // setDisplay(false);
        }
    },[setValue, newValue]);

    return (
        <div className="canvas-modal">
            <form className="single-field-form" onSubmit={handleSubmit}>
                <i id="close" title={"Close"} className="fa-solid fa-x sf-taskbar-btn" onClick={(e)=>{setDisplay(false)}}></i>
                <label className="sf-label" htmlFor="newValue">{labelText}:</label>
                <input
                    className="sf-input"
                    type="text"
                    id="newValue"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    onKeyDown={handleSubmitOnEnterKey}
                />
                <button className="submit-button" type="submit">Submit</button>
            </form>
        </div>
    )
}

export const ExtVarEditModal:FC<{data:EditExternalVarType,setDisplay:React.Dispatch<React.SetStateAction<boolean>>,submitVar:React.Dispatch<React.SetStateAction<any>>}> = ({data, setDisplay, submitVar})=>{
    const [varName, setVarName] = useState<string>(data?.item?.varName || "");
    const [value, setValue] = useState<string|number>(data?.item?.value || "");

    const handleSubmit = useCallback((event: React.FormEvent) => {
        event.preventDefault();
        const params: EditExternalVarType = {
            entityId:data.entityId,
            index:data?.index,
            item:{varName,value},
        }
        submitVar(params);
        setDisplay(false);
    },[varName,value,submitVar, data, setDisplay]);

    return (
        <div id={"ext-var-modal"} className="canvas-modal">
            <form style={{backgroundColor: "#6971e2"}} className="single-field-form" onSubmit={handleSubmit}>
                <i id="close" title={"Close"} className="fa-solid fa-x sf-taskbar-btn" onClick={(e)=>{setDisplay(false)}}></i>
                <label className="sf-label" htmlFor="varName">Variable Name: </label>
                <input
                    className="sf-input"
                    type="text"
                    id="varName"
                    value={varName}
                    onChange={(e) => setVarName(e.target.value)}
                    style={{textAlign:"center"}}
                    required={true}
                />
                <label className="sf-label" htmlFor="varValue">Value: </label>
                <input
                    className="sf-input"
                    type="text"
                    id="varValue"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={(e)=>{
                        if (e.key === 'Enter') {
                            handleSubmit(e);
                        }
                    }}
                    required={true}
                    style={{textAlign:"center"}}
                />
                <button className="submit-button" type="submit">Submit</button>
            </form>
        </div>
    )
}

export default Modal