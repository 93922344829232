import { FC } from "react";

export const FullScreenLoader:FC = ()=>{
    return(
        <div className="loading-container" style={{position:"fixed", width:"100vw", height:"100vh"}}>
            <div className="loading1"></div>
        </div>
    )
}

export default FullScreenLoader;