import { FC, useMemo, Fragment, useCallback, useState, useContext } from "react";
import { ContextField, ExternalVarTable } from "../fcs";
import { Edit, } from "../taskbar-buttons";
import { useSelector } from "react-redux";
import Modal, {SingleFieldModal} from "../canvas/modal";
import { setFormState } from "../redux/form-state-reducers";
import { RootState } from "../redux/store";
import { moduleProps } from "../types";
import { GeneralContext, GeneralContextType } from "../canvas/main";
import { CodeProps } from "../fcs/code-editor.types";

const ExtVarForm:FC<CodeProps&moduleProps> = ({entityId,layer,dispatch})=>{
    const [isShowEditNameModal, setIsShowEditNameModal] = useState(false);
    const {entityHelper, messageHelper} = useContext(GeneralContext) as GeneralContextType;
    const entityName = useSelector<RootState>((state)=>{
        return state.canvas?.[entityId].entityName;
    }) as string;

    const formButtons = useMemo(()=>{
        return {
            entityName:<Edit key={"edit"} title="Edit Entity Name" additionalClass="field-btn" onClick={()=>{setIsShowEditNameModal(true)}}/>,
        }
    },[layer,entityId]);

    const changeEntityName = useCallback((value:string)=>{
        messageHelper.confirm("Make sure there are no compute field that use the previous entity's name as a reference, Proceed?",
            ()=>{
                entityHelper.updateCanvas({entityId, entityName:value});
                setIsShowEditNameModal(false);
            }
        );
    },[]);

    return (
        <Fragment>
            <Modal entityName={entityName || ""} setDisplay={()=>{
                dispatch(setFormState(layer,"",""));
            }}>
                <ContextField fieldName={"entityName"} fieldTitle="Entity Name" buttonProps={[formButtons.entityName]} fieldValue={entityName} />
                <ExternalVarTable entityId={entityId} entityName={entityName}/>
                
            </Modal>
            {isShowEditNameModal && <SingleFieldModal labelText="Entity Name" setValue={changeEntityName} setDisplay={setIsShowEditNameModal} />}
        </Fragment>
    )
}

export default ExtVarForm;