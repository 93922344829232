import {useState, useMemo, useCallback, FC, Fragment, useContext} from "react";
import { useSelector } from "react-redux";
import { CodeEditor, ContextField } from "../fcs";
import { RootState, store } from "../redux/store";
import { moduleProps } from "../types";
import Modal, {SingleFieldModal} from "../canvas/modal";
import { Edit } from "../taskbar-buttons";
import { setFormState } from "../redux/form-state-reducers";
import { GeneralContext, GeneralContextType } from "../canvas/main";
import { layerBasedOnEntTypeName } from "../fcs";

import { CodeProps } from "../fcs/code-editor.types";

const Compute:FC<CodeProps&moduleProps&{computeFieldName:string}> = ({entityId, layer, dispatch, computeFieldName})=>{
    const [isShowEditNameModal, setIsShowEditNameModal] = useState(false);
    const {entityHelper, messageHelper, computeHelper} = useContext(GeneralContext) as GeneralContextType;

    const entityName = useSelector<RootState>((state)=>{
        return state.canvas?.[entityId]?.entityName;
    }) as string;

    const formButtons = useMemo(()=>{
        return {
            entityName:<Edit key={"edit"} title={!computeFieldName?"Edit Entity Name":"Edit Compute Field Name"} additionalClass="field-btn" onClick={()=>{setIsShowEditNameModal(true)}}/>,
        }
    },[layer,entityId,computeFieldName]);

    const labelWidth = useMemo(()=>{
        return computeFieldName ? "150px" : undefined;
    },[computeFieldName]);

    const changeEntityName = useCallback((value:string)=>{
        messageHelper.confirm("Make sure there are no compute field that use the previous entity's name as a reference, Proceed?",
            ()=>{
                entityHelper.updateCanvas({entityId, entityName:value});
                const newFieldName = store.getState().canvas?.[entityId].entityName;
                computeHelper.saveComputeName(entityId, entityName, newFieldName);
                setIsShowEditNameModal(false);
            }
        );
    },[entityName, entityHelper, computeHelper]);

    return (
        <Fragment>
            <Modal entityName={entityName || ""} setDisplay={()=>{
                dispatch(setFormState(layer,"",""));
            }}>
                <ContextField labelWidth={labelWidth} fieldName="computeFieldName" fieldTitle={!computeFieldName?"Entity Name":"Compute Field Name"} fieldValue={computeFieldName || entityName} buttonProps={layer===layerBasedOnEntTypeName.compute ? [formButtons.entityName] : []}/>
                <CodeEditor entityId={entityId} entityName={entityName} computeFieldName={computeFieldName || entityName} dispatch={dispatch}/>
            </Modal>
            {isShowEditNameModal && <SingleFieldModal labelText={!computeFieldName?"Entity Name":"Compute Field Name"} setValue={changeEntityName} setDisplay={setIsShowEditNameModal} />}
        </Fragment>
    )
}

export default Compute;