import { FC, useState, useMemo, Fragment, useCallback, useContext, createContext, useRef } from "react";
import { RootState, AppDispatch, store } from "../redux/store";
import { useSelector } from "react-redux";
import { ToggleDown, ToggleUp, List, Tools, Save, Execute, Copy, ExecOrder } from "../taskbar-buttons";
import {Editor as CmEditor,EditorChange as CmEditorChange} from "codemirror";
import {Controlled as ControlledEditor} from "react-codemirror2";
import { GeneralContext, GeneralContextType } from "../canvas/main";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript";
import { saveCode } from "../redux/compute-reducers";
import ExecutionProgress from "../canvas/exec-progress";
import ExecutionOrder from "./execution-reorder";

import { CodeContextType, FunctionItemProps, CodeProps } from "./code-editor.types";
import { SortedField } from "./column-reorder.types";
import { EntityTypeNames } from "../canvas/entity-objects.types";
import { ExternalVarState } from "../redux/external-data-reducers.types";
import { VariableReference, VariableProps, ParamOutput } from "../utilities/compute-helper.types";
import { SaveCodeProps, ReturnType, ComputeState } from "../redux/compute-reducers.types";

const CodeContext = createContext<CodeContextType|null>(null);

const functionList: FunctionItemProps[] = [
    // {name:"Javascript Arithmetic Functions", docUrl: "https://mathjs.org/docs/reference/functions.html#arithmetic-functions", clipboardValue: "", desc: "Click here to see the documentation"},
    {name:"avg", docUrl: "#", clipboardValue: "avg([entity name].[field name], [expression])", desc: "The AVG() function returns the average value of a numeric column."},
    {name:"count", docUrl: "#", clipboardValue: "count([entity name].[field name], [expression])", desc: "The COUNT() function returns the number of rows that matches a specified criteria"},
    {name:"sum", docUrl: "#", clipboardValue: "sum([entity name].[field name], [expression])", desc: "The SUM() function returns the total sum of a numeric column."},
]

const ExternalVariables:FC<CodeProps> = ({entityId, entityName})=>{
    const [isShowList,setIsShowList] = useState<boolean>(false);
    const data = useSelector<RootState>((state)=>(state.externalVar[entityId])) as ExternalVarState[];
    const {copyToClipboard} = useContext(CodeContext) as CodeContextType;
    // const refEntityName = useSelector<RootState>(state=>state.canvas?.[entityId].entityName) as string;

    return (
        <div className="variable-container">
            <div className="variable-group-title">
                {isShowList && <ToggleUp title="Show Details" additionalClass="var-menu-toggle" onClick={()=>{setIsShowList(false)}}/>}
                {!isShowList && <ToggleDown title="Show Details" additionalClass="var-menu-toggle" onClick={()=>{setIsShowList(true)}}/>}
                <i className="fa-solid fa-list"></i>
                {entityName}
            </div>
            {isShowList && <ul>
                {data.map((item,i)=>{
                    return (
                        <li key={i} title="Click to copy variable to Clipboard"
                            onClick={()=>{
                                copyToClipboard(`${entityName}.${item.varName}`);
                            }}
                            >
                            <Copy title="Click to copy variable to Clipboard" additionalClass=""/>
                            <span style={{marginLeft:"10px"}}>{`${item.varName} = ${isNaN(item.value as number) ? '"' + item.value + '"' : item.value}`}</span>
                        </li>
                    )
                })}
            </ul>}
        </div>
    )
}

const SingleComputeVariables:FC<CodeProps> = ({entityId, entityName})=>{
    // const data = useSelector<RootState>((state)=>(state.externalVar[entityId])) as ExternalVarState[];
    const {copyToClipboard} = useContext(CodeContext) as CodeContextType;
    // const refEntityName = useSelector<RootState>(state=>state.canvas?.[entityId].entityName) as string;

    return (
        <div className="variable-container">
            <div className="variable-group-title" onClick={()=>{
                                copyToClipboard(`${entityName}`);
                            }}>
                <i className="fa-solid fa-calculator"></i>
                <Copy title="Click to copy variable to Clipboard" additionalClass="button"/>
                {entityName}
            </div>
        </div>
    )
}

const DatasetVariables:FC<CodeProps&{isUnreferenced:boolean}> = ({entityId, entityName, isUnreferenced})=>{
    const [isShowList,setIsShowList] = useState<boolean>(false);
    const {entityHelper} = useContext(GeneralContext) as GeneralContextType;

    const columns = useSelector<RootState>(entityHelper.sortedFieldSelector(entityId)) as SortedField[];
    const {copyToClipboard, computeFieldName} = useContext(CodeContext) as CodeContextType;

    return (
        <div className="variable-container">
            <div className="variable-group-title">
                {isShowList && <ToggleUp title="Show Details" additionalClass="var-menu-toggle" onClick={()=>{setIsShowList(false)}}/>}
                {!isShowList && <ToggleDown title="Show Details" additionalClass="var-menu-toggle" onClick={()=>{setIsShowList(true)}}/>}
                <i className="fa-solid fa-table"></i>
                {entityName + (isUnreferenced ? " (Unreferenced)" : "")} 
            </div>
            {isShowList && <ul>
                {Object.values(columns).map((item,i)=>{
                    if(item.fieldName === computeFieldName) return null;
                    return (
                        <li key={i} title="Click to copy variable to Clipboard" 
                        onClick={()=>{
                            copyToClipboard(`${entityName}.${item.fieldName}`);
                        }}
                        >
                            <Copy title="Click to copy variable to Clipboard" additionalClass=""/>
                            <span style={{marginLeft:"10px"}}>{item.fieldName}</span>
                        </li>
                    )
                })}
            </ul>}
        </div>
    )
}

const VariableOption:FC<CodeProps&{isUnreferenced:boolean, refProps:VariableReference | VariableProps}> = ({entityId, entityName, isUnreferenced, refProps})=>{
    // const entityTypeName = useSelector<RootState>((state)=>state.canvas[entityId].entityTypeName);
    const varItem = useMemo(()=>{
        if(refProps.entityTypeName===EntityTypeNames.dataset){
            return (<DatasetVariables entityId={entityId} entityName={entityName} isUnreferenced={isUnreferenced}/>);
        }else if(refProps.entityTypeName===EntityTypeNames.externalVariable){
            return (<ExternalVariables entityId={entityId} entityName={entityName}/>);
        }else if(refProps.entityTypeName===EntityTypeNames.compute){
            return (<SingleComputeVariables entityId={entityId} entityName={entityName}/>);
        }
    },[entityName,refProps]);

    return (
        <Fragment>
        {varItem}
        </Fragment>
    )
}

export const CodeMenuTable:FC<CodeProps&{entityTypeName:EntityTypeNames, references:{[key:string]:VariableReference}, unReferenced: {[key:string]:VariableProps}}> = ({entityId,entityName,entityTypeName, references, unReferenced})=>{
    const [menuActive,setMenuActive] = useState<number>(0);
    
    const onClickMenu = useCallback((index:number)=>{
        setMenuActive(index);
    },[]);

    return (
        <div className="code-menu">
            <div key={"1"} className="code-menu-section">
                <List title="Entities Variables" additionalClass={menuActive===0 ? "code-menu-active" : ""} onClick={()=>{onClickMenu(0)}}></List>
                <Tools title="Functions" additionalClass={menuActive===1 ? "code-menu-active" : ""} onClick={()=>{onClickMenu(1)}}></Tools>
            </div>
            <div key={"2"} className="code-menu-content">
                <div id="variable-list" style={{display:menuActive===0?"initial":"none"}}>
                    {Object.entries(references).map(([userDefEntName, refProps], i) => {
                        return <VariableOption key={`ref-${i}`} entityId={refProps.entityId || ""} entityName={userDefEntName} isUnreferenced={false} refProps={refProps}/>;
                    })}
                    {Object.entries(unReferenced).map(([userDefEntityName, refProps], i) => {
                        return <VariableOption key={`unref-${i}`} entityId={refProps.entityId || ""} entityName={userDefEntityName} refProps={refProps} isUnreferenced={true}/>;
                    })}
                </div>
                <div id="function-list" style={{display:menuActive===1?"initial":"none"}}>
                    <div className="function-item">
                        <a className="fa-solid fa-circle-info" href={"https://mathjs.org/docs/reference/functions.html#arithmetic-functions"} style={{color:"green", textDecoration:"none"}} target="blank" title="Click to see the documentation."></a>
                        <div>Math.js Arithmetic Functions</div>
                    </div>
                    {functionList.map((item, i)=>{
                        return (<div key={i} className="function-item">
                            <a className="fa-solid fa-circle-info" href={item.docUrl} style={{color:"green", textDecoration:"none"}} target="blank" title="Click to see the documentation."></a>
                            <Copy title="Click to copy function to clipboard" additionalClass="button"/>
                            <div title={item.desc}>{item.name}</div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

const CodeEditor:FC<CodeProps&{computeFieldName:string, dispatch:AppDispatch}> = ({entityId,entityName,computeFieldName,dispatch})=>{
    const computeState = useSelector<RootState>((state)=>state.computes?.[entityId]?.[computeFieldName]) as ComputeState;
    const {computeHelper, messageHelper} = useContext(GeneralContext) as GeneralContextType;
    const [script, setScript] = useState<string>(computeState?.code || "//example:\n//x = 5; y = 2;\n//x + y;\n");
    // const [toggleMessage, setToggle] = useState<FadingMessageProps>({toggleOpen:false, message:""});
    const [toggleExecOrder, setToggleExecOrder] = useState<boolean>(false);
    const [execParamOutput, setExecParamOutput] = useState<ParamOutput | undefined>(undefined);

    //selectors
    const entityTypeName = useSelector<RootState>((state)=>state.canvas[entityId].entityTypeName) as EntityTypeNames;
    const references = useSelector<RootState>(computeHelper.referenceSelector(entityId)) as {[key:string]:VariableReference};
    const unReferenced = useSelector<RootState>(computeHelper.unreferencedSelector(entityId)) as {[key:string]:VariableProps};//no relation key or it's not relation owner

    const outputRef = useRef<HTMLDivElement | null>(null);

    const onChange = useCallback((editor: CmEditor, data: CmEditorChange, value: string)=>{
        setScript(value);
    },[]);

    const save = useCallback((script:string, returnType:ReturnType, isSuccessMessage?:boolean)=>{
        if(!returnType){
            messageHelper.warning("Please choose the return type");
            return;
        }

        const saveCodeProps:SaveCodeProps = {
            entityId,
            returnType:returnType,
            fieldName:computeFieldName,
            code:script,
        }
        dispatch(saveCode(saveCodeProps));

        if(isSuccessMessage){
            messageHelper.fadingMsg("Code saved");
            // setToggle({toggleOpen:true,message:"Code saved"});

            // // Hide the message after 2 seconds
            // setTimeout(() => {
            //     setToggle({toggleOpen:false,message:""});
            // }, 1000);
        }
    },[dispatch, entityId, computeFieldName])

    const setError = useCallback((errorDetail:string, prevErrContextMsg:string | undefined, prevEntityName:string | undefined):void=>{
        if(!outputRef.current) return;
        outputRef.current.innerHTML = `
        ${prevErrContextMsg ? `<span>${prevErrContextMsg}</span>` : ""}
        ${prevEntityName ? `<span>${prevEntityName}</span>` : ""}
        <span>${errorDetail}</span>
        `;
    },[outputRef])

    const setOutput = useCallback(():void=>{
        if(!outputRef.current)return;
        const data:string[] | string = computeHelper.getComputeResultPreview(computeState.result, entityTypeName);
        
        let html = "";
        if(entityTypeName === EntityTypeNames.dataset){
            for(const value of data){
                html += `<span>${value}</span>`;
            }
        }else{
            html = `<span>${data}</span>`
        }
        outputRef.current.innerHTML = html;
    },[entityId, computeFieldName, outputRef, computeHelper, computeState, entityTypeName]);

    const execPreview = useCallback(async (script:string, returnType:ReturnType)=>{
        //temporary return type:
        // const returnType = "";
        save(script, returnType);

        const execParams = computeHelper.prepareExecParams(store.getState(), entityId, computeFieldName, script);
        if(!execParams) return;
        
        setExecParamOutput(execParams);
    },[entityId, computeFieldName, save]);

    const copyToClipboard = useCallback((value:string)=>{
        // Use the Clipboard API to write the text to the clipboard
        navigator.clipboard.writeText(value).then(function() {
            messageHelper.fadingMsg("Variable Copied");
        }).catch(function(error) {
            messageHelper.error(`Failed to copy text: ${error}`);
        });
    },[]);

    return (
        <div className="editor-ctrl-container">
            
                <div className="table-toolbar">
                <Save title="Save Code" additionalClass="table-toolbar-btn" onClick={()=>{
                    save(script, computeState?.returnType || ReturnType.number, true);
                }}/>
                <Execute title="Preview Result" additionalClass="table-toolbar-btn" onClick={()=>{
                    execPreview(script, computeState?.returnType || ReturnType.number);
                }}/>
                <ExecOrder title="View/Edit Execution Order" additionalClass="table-toolbar-btn" onClick={()=>{
                    setToggleExecOrder(true);
                }} />
                <div style={{textAlign:"right", flexGrow:"1"}}>
                    <label htmlFor="return-type" style={{color:"#fff"}}>Return Type: </label>
                    <select id="return-type" required={true} value={computeState?.returnType || ReturnType.number} onChange={(e)=>{
                        const val = e.target.value as ReturnType;

                        save(script, val);
                    }}>
                        <option value={ReturnType.none}>{ReturnType.none}</option>
                        <option value={ReturnType.number}>{ReturnType.number}</option>
                        <option value={ReturnType.string}>{ReturnType.string}</option>
                    </select>
                </div>
                </div>
            
            {/* <div className="editor-title">Write Your Compute Script Here</div> */}
            <div className="editor-container">
                <CodeContext.Provider value={{copyToClipboard,computeFieldName}}>
                <CodeMenuTable entityId={entityId} entityName={entityName} entityTypeName={entityTypeName} references={references} unReferenced={unReferenced}/>
                </CodeContext.Provider>
                <div style={{overflowY:"hidden"}}>
                    <ControlledEditor 
                        onBeforeChange={onChange}
                        value={script}
                        className="code-mirror-wrapper"
                        // onCursorActivity={editor=>{setCursor(editor.getCursor())}}
                        options={{
                            lineWrapping:true,
                            lint:true,
                            mode:"javascript",
                            lineNumbers:true,
                            theme:"material",
                        } as any}/>
                    <div className="compute-output">
                        <h5>Output: </h5>
                        <div ref={outputRef} id="output-body">
                            
                        </div>
                    </div>
                </div>
            </div>
            {execParamOutput !== undefined && <ExecutionProgress paramOutput={execParamOutput} 
                                                                 setParamOutput={setExecParamOutput}
                                                                 setConsoleError={setError}
                                                                 isAutoClose={true}
                                                                 setConsoleSuccess={setOutput}/>}
            {toggleExecOrder && <div className="canvas-modal">
                <ExecutionOrder currentEntityName={entityName} currentFieldName={computeFieldName} setDisplay={setToggleExecOrder}/>
            </div>}
        </div>
    )
}

export default CodeEditor;