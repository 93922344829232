import { FC, useRef, useCallback, useState, Fragment, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GeneralContext, GeneralContextType } from "../canvas/main";
import { ItemComputeSortProps } from "./execution-reorder.types";

import { ElementPos } from "../canvas/entity-objects.types";
import { SortedCompute } from "../utilities/compute-helper.types";

const ItemSort:FC<ItemComputeSortProps> = ({fieldName, entityName, index, containerID, sortedFields, entityId, currentEntityName, currentFieldName})=>{
    const Item = useRef<ElementPos | null>(null);
    const {computeHelper} = useContext(GeneralContext) as GeneralContextType;

    const resetMarginTop = useCallback(()=>{
        const container = document.getElementById(containerID);
        const nonDraggedItems = (container ? [...container.querySelectorAll<Element>(".item-sort:not(.dragging)")] : []) as HTMLElement[];

        for(const nonDragged of nonDraggedItems){
            nonDragged.style.marginTop = "0";
        }
    },[containerID]);

    const processSort = useCallback((clientY:number):number=>{
        if(sortedFields.length === 1) return -1;
        const container = document.getElementById(containerID);
        const nonDraggedItems = (container ? [...container.querySelectorAll<Element>(".item-sort:not(.dragging)")] : []) as HTMLElement[];
        const containerOffsetTop = container?.offsetTop || 0;

        let foundFirst:boolean = false;
        let i:number = 0, foundIndex = 0;
        for(const nonDragged of nonDraggedItems){
            let posY = clientY - containerOffsetTop;
            if(posY <= nonDragged.offsetTop + nonDragged.offsetHeight && !foundFirst){
                
                foundFirst = true;
                foundIndex = i;
                
                nonDragged.style.marginTop = `${nonDragged.offsetHeight}px`;
                i++;
                continue;
            }
            nonDragged.style.marginTop = "0";

            i++;
        }
        return foundFirst ? foundIndex : -1;
    },[containerID,sortedFields])

    const mouseMove = useCallback((e:MouseEvent | React.MouseEvent) => {
        if(!Item.current){
            return;
        };
        // Item.current.classList.remove("insert-animation");
        let newY = (Item.current?.startY || 0) - e.clientY;
        Item.current.startY = e.clientY;
        Item.current.style.top = `${Item.current.offsetTop - newY}px`;
        Item.current.foundIndex = processSort(e.clientY);
      },[processSort]);

    const mouseUp = useCallback((e:MouseEvent | React.MouseEvent)=>{
        if(!Item.current) return;
        Item.current.classList.remove("dragging");
        Item.current.style.top = "unset";
        
        document.removeEventListener("mousemove", mouseMove);
        document.removeEventListener("mouseup",mouseUp);
        resetMarginTop();

        const newIndex = (Item.current.foundIndex === -1 ? sortedFields.length - 1 : Item.current.foundIndex) || 0;
        computeHelper.setNewSort({entityId,fieldName,newIndex});
    },[mouseMove, processSort, sortedFields, index, entityId]);

    const mouseDown = useCallback((e:MouseEvent|React.MouseEvent) => {
        if(!Item.current) return;
        const containerOffsetY = Item.current.offsetTop;
        Item.current?.classList.add("dragging");
        Item.current.style.top = containerOffsetY + "px";
        Item.current.startY = e.clientY;
        document.addEventListener("mousemove",mouseMove);
        document.addEventListener("mouseup",mouseUp);
    },[mouseMove,mouseUp]);

    const labelText = useMemo(()=>{
        return `(${index}) ${entityName} >> ${fieldName} ${`${currentEntityName}${currentFieldName}` === entityName + fieldName ? "(Current)":""}`;
    },[entityName, fieldName, currentEntityName, currentFieldName])

    return(
        <div ref={Item} style={{marginTop:"0"}} className={`item-sort non-selectable`}>
            <i title={"Drag To Sort"} className={`fa-solid fa-grip-vertical`} onMouseDown={mouseDown}/>
            
            <label htmlFor={fieldName} title={labelText}>
                {labelText}
            </label>
        </div>
    );
}

const ExecutionOrder:FC<{currentEntityName?:string, currentFieldName?:string, setDisplay:React.Dispatch<React.SetStateAction<boolean>>}> = ({currentEntityName,currentFieldName,setDisplay})=>{
    const {computeHelper} = useContext(GeneralContext) as GeneralContextType;
    const dispatch = useDispatch();
    const fields:SortedCompute[] = useSelector(computeHelper.sortedComputeSelector);
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    
    const containerStyle = {
        height:"95%"
    }

    const id = "exec-list";

    return (
        <Fragment>
            <div className="exec-order">
                <i id="close" title={"Close"} className="fa-solid fa-x sf-taskbar-btn" onClick={(e)=>{setDisplay(false)}}></i>
                <div className="table-container">
                    <div id={id} className="item-sort-container" style={containerStyle}>
                        {fields.map((field,index)=><ItemSort key={field.fieldName} 
                                                            entityName={field.entityName}
                                                            fieldName={field.fieldName} 
                                                            index={index} 
                                                            containerID={id} 
                                                            entityId={field.entityId}
                                                            currentEntityName={currentEntityName}
                                                            currentFieldName={currentFieldName}
                                                            sortedFields={fields}/>)}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

// export const ExecutionOrderModal:FC<CodeProps&{layer: number, setFormState:Function}> = ({entityId, layer, setFormState})=>{
//     const entityName = useSelector<RootState>((state)=>{
//         return state.canvas?.[entityId].entityName;
//     }) as string;

//     const dispatch = useDispatch();

//     return (
//         <Modal entityName={entityName || ""} setDisplay={()=>{
//             dispatch(setFormState(layer,"",""));
//         }}>
//             <ExecutionOrder entityId={entityId} entityName={entityName} />
//         </Modal>
//     )
// }

export default ExecutionOrder;