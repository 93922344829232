import { createSlice } from "@reduxjs/toolkit";
import { Relations, UpdateRelationInfo } from "./reference-reducers.types";

const initialState:{[key:string]:Relations} = {}//key is current entityId

const referenceSlice = createSlice({
    name:"references",
    initialState,
    reducers:{
        createReferenceState: (state, action)=>{
            state = action.payload as {[key:string]:Relations};
            return state;
        },
        addReferences: (state,action)=>{
            const newState = action.payload as {[key:string]:Relations}
            for(const [entityId, item] of Object.entries(newState)){
                state[entityId] = {...(state[entityId] || {}), ...item};
            }
            return state;
        },
        updateReference:(state,action)=>{
            const {entityId,referencedEntityId,referencedEntity,isRelationOwner,relationType,joinColumns,connectorId,} = (action.payload as UpdateRelationInfo);
            const newState = {referencedEntity,isRelationOwner,relationType,joinColumns,connectorId} 
            
            state[entityId][referencedEntityId] = {...state[entityId], ...newState};
        },
        deleteReference:(state,action)=>{
            const entityId = action.payload as string;
            delete state?.[entityId];
        },
        deleteSubReference:(state,action)=>{
            const {entityId, refEntityId} = action.payload as {entityId:string, refEntityId:string};
            delete state?.[entityId]?.[refEntityId];
            if(Object.keys(state?.[entityId] || {}).length === 0){
                delete state?.[entityId];
            }
        }
    }
})

export const {addReferences, updateReference, deleteReference, deleteSubReference, createReferenceState} = referenceSlice.actions;

export const referenceReducer = referenceSlice.reducer;
