import { FC, useState, useCallback, useMemo, useEffect } from "react";
import { ToggleLeft, ToggleRight } from "../taskbar-buttons";
import { useDispatch } from "react-redux";
import WorkspaceHelper from "../utilities/workspace-helper";
import { store } from "../redux/store";
import FullScreenLoader from "../fcs/fullscreen-loading";

export const WorkspaceItem:FC<{title:string, 
                                desc:string, 
                                isActiveIndex:boolean, 
                                setActiveIndex: Function,
                            }> = ({
                                    title, desc, isActiveIndex, setActiveIndex})=>{

    return (
        <div className={`workspace-item ${isActiveIndex ? "ws-active" : ""}`} onClick={()=>{
            setActiveIndex()
        }}>
            <h4>{title}</h4>
            <span><b>Case: </b></span>
            <span>{desc}</span>
        </div>
    );
}

export const Workspaces:FC<{ workspaceItems: {title:string, desc:string}[] }> = ({workspaceItems})=>{
    const [maxTaskbarClass,setToggleTaskbar] = useState(true);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState<boolean>(false);

    const workspaceHelper = useMemo(()=>{
        return new WorkspaceHelper(dispatch);
    }, [dispatch]);

    const toggleTaskbar = useCallback(()=>{
        setToggleTaskbar((state)=>!state);
    },[]);

    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(()=>{
        workspaceHelper.changeWorkspace(0, -1, store.getState());
    },[workspaceHelper]);
    
    return(
    <div className={`sidebar workspaces ${maxTaskbarClass?"side-max side-max-ws":""}`}>
        <div className={`side-taskbar ${maxTaskbarClass?"side-taskbar-max":""}`}>
            {maxTaskbarClass && <h4 style={{marginRight:"auto", marginTop:0, marginBottom:0}}>Workspaces</h4>}
            {/* <Add title={"Add New Workspace"} additionalClass="taskbar-btn" /> */}
            {maxTaskbarClass && <ToggleLeft title={"Hide Workspace Explorer"} onClick={toggleTaskbar} additionalClass="taskbar-btn"/>}
            {!maxTaskbarClass && <ToggleRight title={"Display Workspace Explorer"} onClick={toggleTaskbar} additionalClass="taskbar-btn"/>}
        </div>
        <div id="ent-obj-container">
            {workspaceItems.map((item, i)=>{
                return <WorkspaceItem key={i} title={item.title} desc={item.desc} isActiveIndex={activeIndex === i} setActiveIndex={()=>{
                    try{
                        setLoader(true);
                        workspaceHelper.changeWorkspace(i, activeIndex, store.getState());
                        setActiveIndex(i)
                    }finally{
                        setTimeout(()=>{
                            setLoader(false);
                        }, 1000);
                    }
                }}/>
            })}
        </div>
        {loader ? <FullScreenLoader /> : null}
    </div>
    );
}