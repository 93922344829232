import { FC, Fragment, useRef, useEffect, useState, useContext } from "react";
import { GeneralContext, GeneralContextType } from "./main";
import { ComputeError } from "../types";

import { ParamOutput, ParamVar, VariableReference } from "../utilities/compute-helper.types";
import { SingleResponseSuccess } from "../redux/compute-reducers.types";

const ProgressBar:FC<{paramVar:ParamVar, varRef:{[key:string]:VariableReference}, index:number, execIndex:number,
                      setExecIndex: React.Dispatch<React.SetStateAction<number>>, 
                      setCurrentIndex: React.Dispatch<React.SetStateAction<number>>, 
                      error:string, 
                      setError:React.Dispatch<React.SetStateAction<string>>,
                      aliases:ParamOutput["aliases"],
}> = ({paramVar, varRef, index, execIndex, setExecIndex, setCurrentIndex, error, setError, aliases})=>{
    const progressRef = useRef<HTMLDivElement | null>(null);
    const {computeHelper, messageHelper} = useContext(GeneralContext) as GeneralContextType;
    const [toggleErr, setToggleErr] = useState<boolean>(false);

    const [progress, setProgress] = useState<string>("0%");
    useEffect(()=>{
        setProgress("0%");
        if(!progressRef.current) return;
        progressRef.current.style.width = "0";
    }, [progressRef]);

    useEffect(()=>{
        if(index !== execIndex) return;
        if(!progressRef.current) return;

        setCurrentIndex(index);

        const params = {
            relationPath:varRef,
            codeProps:paramVar,
            aliases,
        }

        let isError = false;
        if(paramVar.apiEntityName && !paramVar.isPreview){
            const execDatasetCompute = async ()=>{
                const lenResp = await fetch(`${process.env.REACT_APP_API_URL}/compute/data-length?entityName=${paramVar.apiEntityName}&datasetId=${paramVar.datasetId}`)
                if(!lenResp.ok){
                    messageHelper.error("Failed to identify the size of the data");
                    return;
                }
                
                const datasetLen = Number(await lenResp.json() || 0) as number;

                const response = await fetch(`${process.env.REACT_APP_API_URL}/compute/exec-expressions`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(params),
                });

                if(!response.body) return;
                const reader = response.body.pipeThrough(new TextDecoderStream('utf-8')).getReader();
        
                let i = 0;
                let resultCount = 0;
                while (true) {
                    let {done, value} = await reader.read();
                    if (done) {
                        break;
                    }
                    
                    if(/\\nerror:/gi.test(value || "")){
                        isError = true;
                        setError((value || "").replace(/\\nerror:/g,"Error: "));
                        setToggleErr(true);
                    }else if(value){
                        
                        value = value.slice(0,-1);
                        const objValues = JSON.parse(`{${value}}`);
                        resultCount += Object.keys(objValues).length;
                        
                        const width = resultCount && datasetLen ? (Math.ceil((resultCount / datasetLen) * 100)): 0;
                        
                        if(progressRef.current){
                            setProgress(`${width}%`);
                            progressRef.current.style.width = `${width}%`;
                        }
                        computeHelper.pushResult({entityId:paramVar.entityId || "", fieldName:paramVar.fieldName, result:objValues})
                    }
                    i++;
                }
                if(!isError){
                    setExecIndex((state)=>state + 1);
                }
            }
            computeHelper.saveResult({entityId: paramVar.entityId as string,fieldName:paramVar.fieldName, result:{}});//reset the result before executing
            execDatasetCompute();
        }else{
            const execCompute = async ()=>{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/compute/exec-expression`,{
                    method:"POST",
                    headers:{'Content-Type':'application/json'},
                    body:JSON.stringify(params)
                });
                let data:SingleResponseSuccess | ComputeError = await response.json();
                if(!response.ok){
                    isError = true;
                    setError((data as ComputeError).error.message);
                    setToggleErr(true);
                }else{
                    const saveData = {"0":(data as SingleResponseSuccess).result as string|number|undefined};
                    computeHelper.saveResult({
                        entityId:paramVar.entityId || "",
                        fieldName:paramVar.fieldName,
                        result:saveData
                    });
                    if(progressRef.current){
                        progressRef.current.style.width = "100%";
                        setProgress("100%");
                    }
                }

                if(!isError){
                    setExecIndex((state)=>state + 1);
                }
            }
            execCompute();
        }
    },[paramVar, varRef, index, execIndex, computeHelper]);
    return (
        <Fragment>
            <div className="progress-item">
                <div className="progress-container">
                    <div style={{border:"1px solid black"}}><div ref={progressRef} className="progress-bar"></div></div>
                    <span style={{textAlign:"center"}}>{progress}</span>
                </div>
                <span>{`${paramVar.entityAliasName} >> ${paramVar.fieldName}`}</span>
                {toggleErr && <p id="error" style={{color:"red"}}>Execution failed, <b><i id="err-click" onClick={()=>{
                    messageHelper.error(error);
                }}>{"click here >>"}</i></b> to see the details</p>}
            </div>
        </Fragment>
    )
}

const ExecutionProgress:FC<{paramOutput:ParamOutput, 
                            setParamOutput:React.Dispatch<React.SetStateAction<ParamOutput | undefined>>,
                            isAutoClose?:boolean,
                            setConsoleError?:(errorDetail:string, prevErrContextMsg:string | undefined, prevEntityName:string | undefined)=>void,
                            setConsoleSuccess?:()=>void
                        }> = ({paramOutput, setParamOutput, isAutoClose, setConsoleError, setConsoleSuccess})=>{
    
    const {messageHelper} = useContext(GeneralContext) as GeneralContextType;
    const [execIndex, setExecIndex] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const [error, setError] = useState<string>("");
    
    useEffect(()=>{
        if(error) setExecIndex(paramOutput.codeProps.length);
    }, [error]) 

    useEffect(()=>{
        if(!isAutoClose) return;
        const len = paramOutput.codeProps.length;
        if(error){
            if(typeof setConsoleError === "function"){
                const PreviousErr1 = currentIndex < len -1 ? `Previous entity error interupted the current entity process` : undefined;
                const PreviousErr2 = currentIndex < len -1 ? `Entity Name: ${paramOutput.codeProps[currentIndex]?.entityAliasName}` : undefined;
                setConsoleError(error, PreviousErr1, PreviousErr2);
            }
            messageHelper.error("Error occured during execution, please view the detail on the output console");
            setParamOutput(undefined);
        }else if(!error && execIndex >= paramOutput.codeProps.length && typeof setConsoleSuccess === "function"){
            setConsoleSuccess();
            setTimeout(() => {
                setParamOutput(undefined);
            }, 1000);
        }
    },[isAutoClose, execIndex, currentIndex, paramOutput.codeProps, error, setParamOutput, setConsoleError, setConsoleSuccess]);

    return (
        <div className="canvas-modal">
            <div className="progress-modal-body">
                <span>Progress</span>
                <div className="progress-list-container">
                    {paramOutput.codeProps.map((item,index)=>{
                        const varRef = paramOutput.relationPath[item.entityAliasName];
                        return <ProgressBar key={index} paramVar={item} varRef={varRef} 
                                            index={index} execIndex={execIndex} 
                                            setExecIndex={setExecIndex} error={error} setError={setError}
                                            aliases={paramOutput.aliases}
                                            setCurrentIndex={setCurrentIndex}/>
                    })}
                    {paramOutput.codeProps.length === 0 && <p style={{textAlign:"center"}}><b>Empty Compute Field</b></p>}
                </div>
                {!isAutoClose && <button type="button" className="module-button" disabled={execIndex <= paramOutput.codeProps.length - 1} onClick={()=>{
                    setParamOutput(undefined);
                }}>Close</button>}
            </div>
        </div>
    )
}

export default ExecutionProgress;