import { AppDispatch } from "../redux/store"
import { RootState } from "../redux/store";
import { createSelector } from "reselect";
import { saveResult, pushResult, setNewExecSort, changeComputeFieldName } from "../redux/compute-reducers";
import { MessageHelper } from "../fcs/message";

import { EntitiesState } from "../redux/canvas-reducers.types";
import { EntityTypeNames } from "../canvas/entity-objects.types";
import { DataReducerState, DataReducerStates } from "../redux/data-reducers.types";
import { Relations } from "../redux/reference-reducers.types";
import { ComputeSliceState, ReturnType, SaveResultProps, SetNewSortProps, ResultType } from "../redux/compute-reducers.types";
import { ExternalVarState } from "../redux/external-data-reducers.types";
import { VariableProps, VariableReference, ParamVar, ExternalVarStateType, NameMap, SortedCompute, ParamOutput, VarProps } from "./compute-helper.types";

export class ComputeHelper{
    private getReferenceState = (state:RootState):{[key:string]:Relations}=>state.references;
    private getDataState = (state:RootState):{[key:string]:DataReducerState}=>state.data;
    private getCanvasState = (state:RootState):EntitiesState=>state.canvas;
    private getComputeState = (state:RootState):{[key:string]:ComputeSliceState}=>state.computes;
    constructor(private dispatch:AppDispatch, private messageHelper: MessageHelper){};

    private pushConnectedId = (refEntityIds:{[key:string]:VariableReference}, entityId:string, ApiEntityName:string = "", references: {[key:string]:Relations}, canvas:EntitiesState, chainRelationPath:VariableReference["relationPath"]=[], isHideEntityIdProp?:boolean)=>{
        
        if(!references[entityId]) return;
        for(const [refEntityId, props] of Object.entries(references[entityId])){
            if(!props.isRelationOwner) continue;
            const entityTypeName = canvas[refEntityId].entityTypeName;
            chainRelationPath?.push({
                ApiEntityName:ApiEntityName,
                foreignKey:(props.joinColumns || []).map(({foreignKey})=>foreignKey)
            });
            const prop = {
                entityId: isHideEntityIdProp ? undefined : refEntityId,
                ApiEntityName:props.referencedEntity || "",
                entityTypeName,
                relationPath:[...chainRelationPath]
            };
            if(isHideEntityIdProp) delete prop.entityId;
            refEntityIds[canvas[refEntityId].entityName] = prop;
            if(references[refEntityId]){
                this.pushConnectedId(refEntityIds, refEntityId, props.referencedEntity, references, canvas, chainRelationPath, isHideEntityIdProp);
            }
        }
    }

    reference = (entityId:string, references:{[key: string]: Relations}, data:{[key: string]: DataReducerState}, canvas:EntitiesState)=>{
        // const referencedEntityId:connectedEntityResult[] = [];
        const refEntityIds:{[key:string]:VariableReference} = {};
        const currentApiEntityName = data?.[entityId]?.apiEntityName || "";
        if(!currentApiEntityName) return {};
        const entityTypeName = canvas[entityId].entityTypeName;
        refEntityIds[canvas[entityId].entityName] = {
            entityId:entityId,
            ApiEntityName: currentApiEntityName,
            entityTypeName,
        };
        this.pushConnectedId(refEntityIds, entityId, currentApiEntityName, references, canvas, []);
        return refEntityIds;
    }

    referenceSelector(entityId:string){
        const selector = createSelector(
            [this.getReferenceState, this.getDataState, this.getCanvasState],
            (references, data, canvas)=>this.reference(entityId, references, data, canvas)
        )
        return selector;
    }

    multipleReference = (references:{[key: string]: Relations}, data:{[key: string]: DataReducerState}, canvas:EntitiesState, computes:{[key:string]:ComputeSliceState})=>{
        const allRef:{[key:string]:{[key:string]:VariableReference}} = {};
        for(const entityId of Object.keys(references)){
            if(!computes?.[entityId]) continue;
            const refEntityIds: {[key:string]:VariableReference} = {};
            const currentApiEntityName = data?.[entityId]?.apiEntityName || undefined;
            const entityTypeName = canvas[entityId].entityTypeName;
            refEntityIds[canvas[entityId].entityName] = {
                // entityId:entityId,
                ApiEntityName: currentApiEntityName,
                entityTypeName,
            };
            this.pushConnectedId(refEntityIds, entityId, currentApiEntityName, references, canvas, [], true);
            const entityName = canvas[entityId].entityName;
            allRef[entityName] = refEntityIds;
        }
        return allRef;
    }

    multipleReferenceSelector = createSelector(
        [this.getReferenceState, this.getDataState, this.getCanvasState, this.getComputeState],
        this.multipleReference
    );

    unreferenced = (entityId: string, references: {[key: string]: Relations}, canvas:EntitiesState)=>{
        // const referencedEntityId:connectedEntityResult[] = [];
        const refEntityIds:{[key:string]:VariableProps} = {}; //{[entityName] : [entityId]}
                
        for(const [refEntityId, props] of Object.entries(references)){
            const entityName = canvas[refEntityId].entityName;
            const entityTypeName = canvas[refEntityId].entityTypeName;
            if(props[entityId]){
                refEntityIds[entityName] = {
                    entityId: refEntityId,
                    entityName: canvas[refEntityId].entityName,
                    entityTypeName,
                }
            }
        }

        return refEntityIds;
    }

    unreferencedSelector = (entityId:string)=>{//connected by connector but no relation key or it's not the relation owner
        const selector = createSelector(
            [this.getReferenceState, this.getCanvasState],
            (references, canvas)=>this.unreferenced(entityId,references,canvas)
        )
        return selector;
    }

    saveComputeName = (entityId:string, oldFieldName:string, newFieldName:string)=>{
        this.dispatch(changeComputeFieldName({entityId,oldFieldName,newFieldName}));
    }

    getVariableFromCode = (code:string, 
        computeStates:{[key:string]:ComputeSliceState}, 
        externalVarStates:{[key:string]:{[key:string]:{[key:string]:any}}}, 
        nameMap:NameMap)
        :VarProps[]=>{
        
        const nonSingleEntities = Object.entries(nameMap).map(([entityName, props])=>{
            return props.entityTypeName === EntityTypeNames.compute ? undefined : entityName;
        }).filter((entityName)=>entityName).join("|");

        const singleComputeEntities = Object.entries(nameMap).map(([entityName, props])=>{
            return props.entityTypeName === EntityTypeNames.compute ? entityName : undefined;
        }).filter((entityName)=>entityName).join("|");
        
        // const regPattern = `(${nonSingleEntities})(\\(("[a-zA-Z]+"|[0-9]+)\\))?\\.([a-zA-Z]+)([0-9]+)?(\\s|\\\\n|;|[+\\-\\/*%])?`;
        const regPattern = `(${nonSingleEntities})\\.([a-zA-Z_0-9]+)(\\s|\\\\n|;|[+\\-\\/*%])?`;
        const singleComputePattern = `(${singleComputeEntities})(\\s|\\n|;|[+\\-\\/*%])`;
        
        code = code+=";";
        const regex = new RegExp(regPattern,'g');
        const singleCompRegex = new RegExp(singleComputePattern,'g');

        const variables = [...(nonSingleEntities ? (code.match(regex) || []) : []), ...(singleComputeEntities ? (code.match(singleCompRegex) || []) : [])];
        
        // const variables = code.match(regex) || []
        return variables.map((varName)=>{
            varName = varName.replace(/\s|\\n|;|[+\-\/*%]/g,"");
            let entityId;
            if(varName.indexOf(".") !== -1){
                const [entityName,fieldName] = varName.split(".",2);
                entityId = nameMap[entityName].entityId;

                const isDatasetCompute = computeStates?.[entityId]?.[fieldName] ? true : false;

                if(nameMap[entityName].entityTypeName === EntityTypeNames.externalVariable){ 
                    return {
                        varName,
                        value: externalVarStates[entityId]?.[fieldName],
                    }
                }else{ // no value because backend will identify the referenced value from dataset
                    return {
                        varName:varName,
                        apiEntityName: nameMap?.[entityName]?.apiEntityName,//backend will fetch the value
                        datasetId: nameMap?.[entityName]?.datasetId,
                        isDatasetCompute,
                    }
                }
            }else{ 
                entityId = nameMap[varName].entityId;
                return {
                    varName,
                    value: computeStates[entityId]?.[varName]?.result?.["0"],
                }
            }
        });
    }

    private getFieldState = (state:RootState):{[key:string]:ExternalVarState[]}=>state.externalVar;

    externalVarState = (extVarStates:{[key: string]: ExternalVarState[]})=>{
        const entityStates:ExternalVarStateType = {};
        for(const [entityName, fields] of Object.entries(extVarStates)){
            const objFields = fields.reduce((varProps:{[key:string]:string|number}, item)=>{
                varProps[item.varName] = item.value;
                return varProps
            },{});
            Object.assign(entityStates,{[entityName]:objFields});
        }
        return entityStates;
    }

    externalVarStateSelector = createSelector([this.getFieldState],this.externalVarState);

    nameMap = (canvas:EntitiesState, data:{[key: string]: DataReducerState})=>{
        return Object.entries(canvas).reduce((obj:NameMap,[entityId,props])=>{
            obj[props.entityName] = {
                entityId,
                entityName: props.entityName,
                entityTypeName: props.entityTypeName,
                apiEntityName: data?.[entityId]?.apiEntityName || undefined,
                datasetId:data?.[entityId]?.datasetId,
            }
            return obj;
        },{});
    };

    nameMapSelector = createSelector(
        [this.getCanvasState, this.getDataState],
        this.nameMap
    )

    getApiEntityName = (canvasState: EntitiesState, dataState: DataReducerStates):{[key:string]:string}=>{
        const result:{[key:string]:string} = {};
        for(const [entityId,prop] of Object.entries(dataState)){
            const entityName = canvasState?.[entityId]?.entityName || "";
            if(prop.apiEntityName){
                result[entityName] = prop.apiEntityName;
            }
        }
        return result;
    }

    prepareExecParams = (
        store:RootState,
        currentEntityId?:string,
        currentFieldName?:string,
        unsavedScript?:string,
    ):ParamOutput | false=>{
        const varRef = this.multipleReference(store.references, store.data, store.canvas, store.computes);
        const externalVarStates = this.externalVarState(store.externalVar);
        const nameMap = this.nameMap(store.canvas, store.data);
        
        const params:ParamVar[] = [];
        const sorted = this.sortedCompute(store.computes, store.canvas, store.data);
        for(const fieldProps of sorted){//entities loop
            const variables = this.getVariableFromCode(fieldProps.code, store.computes, externalVarStates, nameMap);
            if(!fieldProps.returnType){
                this.messageHelper.warning(`Please choose return type for: ${fieldProps.entityName} >> ${fieldProps.fieldName}`);
                return false;
            }
            const isCurrentEntity = currentEntityId === fieldProps.entityId && currentFieldName === fieldProps.fieldName;
            params.push({
                entityId: fieldProps.entityId,
                fieldName:fieldProps.fieldName,
                code: isCurrentEntity ? (unsavedScript || "") : fieldProps.code,
                returnType: fieldProps.returnType,
                apiEntityName: fieldProps.apiEntityName,
                entityAliasName: fieldProps.entityName,
                datasetId:fieldProps.datasetId,
                variables: variables,
                isCacheOutput: true,
            });
            if(isCurrentEntity) break;
        }
        //last entity params doesn't need to be cached
        if(params.length) params[params.length - 1].isCacheOutput = false;

        const paramOutput:ParamOutput = {
            relationPath: varRef,
            codeProps: params,
            aliases: this.getApiEntityName(store.canvas, store.data),
        };
        return paramOutput;
    }

    saveResult = (result:SaveResultProps)=>{
        this.dispatch(saveResult(result));
    }

    pushResult = (result:SaveResultProps)=>{
        this.dispatch(pushResult(result));
    }

    sortedCompute = (computeState:{[key: string]: ComputeSliceState}, canvasState: EntitiesState, dataState: {[key: string]: DataReducerState}):SortedCompute[]=>{
        const sortedFields = Object.entries(computeState).map(([entityId, props])=>{
            return Object.entries(props).map(([fieldName,subProps])=>({
                entityId,
                fieldName,
                entityName:canvasState[entityId].entityName,
                entityTypeName:canvasState[entityId].entityTypeName,
                apiEntityName:dataState?.[entityId]?.apiEntityName,
                datasetId:dataState?.[entityId]?.datasetId,
                code:subProps.code,
                sortIndex: subProps.sortIndex || 0,
                returnType:subProps.returnType,
            })) || [];
        }) || [];
        return sortedFields.flat().sort((a, b) => a.sortIndex - b.sortIndex);
    };

    getComputeResultPreview = <T>(computeStateResult:ResultType, entityTypeName: EntityTypeNames):T=>{
        let result:T;
        if(entityTypeName === EntityTypeNames.dataset){
            let i = 0;
            result = [] as T;
            for(const [id, value] of Object.entries(computeStateResult)){
                (result as string[]).push(`id: ${id}, value: ${value}`);
                i++;
                if(i === 5) break;
            }
        }else if(entityTypeName === EntityTypeNames.compute){
            result = computeStateResult["0"] as T;
        }else{
            result = 'No Data' as T;
        }
        return result as T;
    }

    sortedComputeSelector = createSelector(
        [this.getComputeState, this.getCanvasState, this.getDataState],
        this.sortedCompute
    )

    setNewSort = (setNewSortProps:SetNewSortProps)=>{
        this.dispatch(setNewExecSort(setNewSortProps));
    }
}