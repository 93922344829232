export const enum ReturnType{
    none="",
    string="string",
    number="number",
}

export type ResultType = {
    [key:string|number]:string|number|undefined,
}

export interface ComputeState{
    code:string;
    returnType: ReturnType | "";
    sortIndex:number;
    result:ResultType;
}

export interface ComputeSliceState{
    [key:string]: ComputeState //key is compute fieldName
}

export interface ComputeProps{
    entityId:string;
    fieldName:string;
}

export interface SaveCodeProps extends ComputeProps{
    code:string;
    returnType:ReturnType;
}

export interface SaveResultProps extends ComputeProps{
    result:{[key:string|number]:string|number|undefined};
}

export type SingleResponseSuccess = {result:string|number|undefined};

export interface SetNewSortProps{
    entityId:string;
    fieldName:string;
    newIndex:number;
}