import { FC } from "react";
import { createSlice } from "@reduxjs/toolkit";

import { MessageState, MessageType } from "./message-reducer.types";

const initialState:MessageState = {
    message:"",
    messageType: MessageType.messageFading
}

const messageSlice = createSlice({
    name:"message",
    initialState,
    reducers:{
        createMessageState:(state,action)=>{
            return initialState;
        },
        setMessage:(state,action)=>{
            state = action.payload as MessageState;
            return state;
        },
        closeMessage:(state,action)=>{
            state.message = "";
            return state;
        }
    }
});

export const {setMessage, closeMessage, createMessageState} = messageSlice.actions;

export const messageReducer = messageSlice.reducer;