import { FC, useMemo, Fragment, useCallback, useEffect, useState, useContext } from "react";
import { ContextField, SelectField, Table } from "../fcs";
import { Edit } from "../taskbar-buttons";
import { fieldSize, moduleProps } from "../types";
import { useSelector } from "react-redux";
import Modal, {SingleFieldModal} from "../canvas/modal";
import { setFormState } from "../redux/form-state-reducers";
import { RootState, store } from "../redux/store";
import axios from "axios";
import { GeneralContext, GeneralContextType } from "../canvas/main";
import { retrieveDatasetGql, resetData } from "../redux/data-reducers";
import { useApolloClient, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {Loader} from "../fcs";

import { CodeProps } from "../fcs/code-editor.types";
import { DatasetsCallerProps } from "../redux/dataset-selector-reducers.types";
import { DataReducerState, GqlParamsType } from "../redux/data-reducers.types";
import { CollectionResponse } from "../utilities/canvas-helper.types";

const DatasetForm:FC<CodeProps&moduleProps> = ({entityId,layer,dispatch})=>{
    const {entityHelper, messageHelper} = useContext(GeneralContext) as GeneralContextType;
    const [isShowEditNameModal, setIsShowEditNameModal] = useState(false);
    const apolloClient = useApolloClient();
    const [loading, setLoading] = useState<Boolean>(false);

    const entityName = useSelector<RootState>((state)=>{
        return state.canvas?.[entityId].entityName;
    }) as string;

    const {datasetId, datasetContext} = useSelector<RootState>((state)=>{
        return state.data[entityId];
    }) as DataReducerState;

    const datasetFieldName = useMemo(()=>{
        return "datasets";
    },[]);

    const changeEntityName = useCallback((value:string)=>{
        messageHelper.confirm("Make sure there are no compute field that use the previous entity's name as a reference, Proceed?",
            ()=>{
                entityHelper.updateCanvas({entityId, entityName:value});
                setIsShowEditNameModal(false);
            }
        );
    },[]);

    const retrieve = useCallback((pageIndex?:number)=>{
        const args:GqlParamsType = {
            client:apolloClient as ApolloClient<NormalizedCacheObject>,
            entityId:entityId,
            pageIndex:pageIndex,
        }
        dispatch(retrieveDatasetGql(args))
    },[dispatch]);

    useEffect(()=>{
        if(datasetId){
            retrieve();
        }
    },[datasetId, retrieve]);

    const datasetChanged = useCallback(async (e:React.ChangeEvent<HTMLSelectElement>)=>{
        try{
            const keyValue = e.target.value;
            const datasets:DatasetsCallerProps = store.getState()?.selectElements[datasetFieldName]?.data?.[keyValue];
            const {posX, posY} = store.getState()?.canvas[entityId];

            if(datasets){
                const url = (process.env.REACT_APP_API_URL || "") + (datasets.apiUrl || "");
                const configs = {params: {...datasets.params, datasetId:keyValue}};

                setLoading(true);
                const response = await axios.get(url, configs);
                let datasetContext = e.target.options[e.target.selectedIndex].text || "";
                entityHelper.generateRelatedEntity(keyValue, entityId, response.data.result as CollectionResponse, posX, posY, datasets, datasetContext);
                dispatch(setFormState(layer,"",""));
                messageHelper.info("Relational data detected, sub entity generated successfully!");
            }
        }finally{
            setLoading(false);
        }

    },[datasetFieldName,entityHelper, dispatch]);

    const formButtons = useMemo(()=>{
        return {
            entityName:<Edit key={"edit"} title="Edit Entity Name" additionalClass="field-btn" onClick={()=>{setIsShowEditNameModal(true)}}/>,
        }
    },[layer,entityId]);

    useEffect(()=>{
        return (()=>{
            dispatch(resetData(entityId));
        }); 
    },[entityId, dispatch])

    return (
        <Fragment>
            <Modal entityName={entityName || ""} setDisplay={()=>{
                dispatch(setFormState(layer,"",""));
            }}>
                <ContextField fieldName={"entityName"} fieldTitle="Entity Name" buttonProps={[formButtons.entityName]} fieldValue={entityName} />
                {datasetContext !== undefined ? <ContextField fieldName={"entityContext"} fieldTitle="Dataset Collection" buttonProps={[]} fieldValue={datasetContext || ""}/> : <></>}
                {datasetContext === undefined ? <SelectField fieldName={datasetFieldName} fieldTitle="Choose Dataset" fieldValue="" width={fieldSize.large} url={process.env.REACT_APP_API_URL + "/demo-datasets"} onChange={datasetChanged}/> : <></>}
                <Table entityId={entityId} entityName={entityName} width="initial" height="initial" retrieve={retrieve} />
                {loading ? <Loader /> : <></>}
            </Modal>
            {isShowEditNameModal && <SingleFieldModal labelText="Entity Name" setValue={changeEntityName} setDisplay={setIsShowEditNameModal} />}
        </Fragment>
    )
}

export default DatasetForm;

