import { FC, useEffect, useCallback } from "react";
import { Close } from "../taskbar-buttons";
import { setFormState } from "../redux/form-state-reducers";
import { useDispatch } from "react-redux";
import { layerBasedOnEntTypeName } from "../fcs";
import "../../css/home.css";

const Docs:FC = ()=>{
    const videoPlay = useCallback(()=>{
        const visitedFlag = localStorage.getItem("ddcmFlag");

        if(!visitedFlag){
            localStorage.setItem("ddcmFlag","1");
        }
    },[]);

    const dispatch=useDispatch();
    return (
        <div id="docs" className="canvas-modal" style={{paddingTop:"0"}}>
            <div className={"docs-container"}>
            <Close title="Close" additionalClass="docs-close-btn" onClick={()=>{
                dispatch(setFormState(layerBasedOnEntTypeName.docs, "", ""));
            }}/>
            <nav className={"docs-nav"}>
                <ul>
                    <li><a href="#quick-start" >Quick Starts</a></li>
                    <li><a href="#compute-code" >Code</a></li>
                    <li><a href="#functions" >Functions</a></li>
                </ul>
            </nav>
                <div className="docs-body">
                    <div id="quick-start" className="guide-part">
                        <h2>Quick Starts</h2>
                        <div className="video-container">
                            <video id="ddcm-video" controls poster="/home/img/sample-vid1.png" controlsList="nodownload" onClick={videoPlay}>
                                <source src="/home/file/ddcm-overview.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <section className="tutorial-with-images">
                            <article>
                                <h4 >Single Compute Entity</h4>
                                <ol className="lists">
                                    <li>
                                        <p>Drag the 'Single Compute Entity' into the canvas</p>
                                    </li>
                                    <li>
                                        <p>Click on the edit button.</p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-scf-1.png' alt="Drag component to the screen"/>
                            </aside>
                        </section>
                        <section className="tutorial-with-images">
                            <article>
                                <ol className="lists" start={3}>
                                    <li>
                                        <p>Once the form is open, try to type: <i>"2 + 2"</i> on the code editor and 
                                        click on preview result when you're done typing to test if the application is running.
                                        </p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-scf-2.png' alt="Code Editor"/>
                            </aside>
                        </section>
                        <section className="tutorial-with-images">
                            <article>
                                <ol className="lists" start={4}>
                                    <li>
                                        <p>The result will appear at the bottom of the code editor.
                                        </p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-scf-3.png' alt="Code Editor"/>
                            </aside>
                        </section>

                        <section className="tutorial-with-images">
                            <article>
                                <h4 >Dataset Compute</h4>
                                <p>Dataset Compute will allow you to do calculation for a large amounts of data. In addition, it also detects entity relations through predeclared primary key & foreign key from the server.</p>
                                <ol className="lists" start={1}>
                                    <li>
                                        <p>Drag and drop the Dataset Compute entity to the canvas and click the edit button.
                                        </p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-dsc-1.png' alt="Drag component to the screen"/>
                            </aside>
                        </section>
                        <section className="tutorial-with-images">
                            <article>
                                <ol className="lists" start={2}>
                                    <li>
                                        <p>Choose one of the provided dataset options.
                                        </p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-dsc-2.png' alt="Choose dataset"/>
                            </aside>
                        </section>
                        <section className="tutorial-with-images">
                            <article>
                                <ol className="lists" start={3}>
                                    <li>
                                        <p>Once the dataset has been chosen, the collections of entity - sub entity relations will be generated.
                                        </p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-dsc-3.png' alt="Entity generated"/>
                            </aside>
                        </section>
                        <section className="tutorial-with-images">
                            <article>
                                <ol className="lists" start={4}>
                                    <li>
                                        <p>To add a compute field, click on 'Column Setup' button, next click the 'Add Compute Field' button.
                                        </p>
                                    </li>
                                    <li>
                                        <p>You can also rearrange the the field display order.
                                        </p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-dsc-4.png' alt="Add a compute field"/>
                            </aside>
                        </section>
                        <section className="tutorial-with-images">
                            <article>
                                <ol className="lists" start={6}>
                                    <li>
                                        <p>Click on the 'Edit Formula' button to write a code for the newly created compute field.
                                        </p>
                                    </li>
                                    <li>
                                        <p>The next procedure is no different from the previous guide.
                                        </p>
                                    </li>
                                </ol>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/qs-dsc-5.png' alt="Add a compute field"/>
                            </aside>
                        </section>
                    </div>

                    <div id="compute-code" className="guide-part">
                        <h2>Code</h2>
                        <p>This app is using math.js library for arithmetic operations and the conditional logic operation also quite similar to javascript.</p>
                        <section>
                            <h4>Variable Declaration</h4>
                            <p>Variable declarations are written with the following pattern: <i>[<b>variable name</b>] = [<b>variable value</b>]</i> (without []) and no space allowed for the variable name, 
                            and ended with a semicolon (;).</p>
                            <p>For example: </p>
                            <pre>
                                <code>
                                a = 10;<br/>
                                b = a + 2;
                                </code>
                            </pre>
                            <p>In the end, it will detect the most recent calculation as the output.</p>
                            <p>Output: <i><b>12</b></i></p>
                        </section>
                        <section>
                            <h4>Non Number Declaration (string)</h4>
                            <p>Same as the recent example, but we need to add single/double quote</p>
                            <p>For example: </p>
                            <pre>
                                <code>
                                a = "john";<br/>
                                b = "doe";
                                </code>
                            </pre>
                        </section>

                        <section className="tutorial-with-images">
                            <article>
                                <p><b>Important note!</b></p>
                                <p>We need to change the return type, whether it will be string or number as an output in the code editor.</p>
                            </article>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/cc-var1.png' alt="return type" style={{maxWidth:"350px"}}/>
                            </aside>
                        </section>

                        <section>
                            <h4>Conditional Logics (if, else if, & else)</h4>
                            <p>Example: </p>
                            <aside className="image-wrapper">
                                <img className="guide-img" src='/guide/cc-con1.png' alt="conditional logic" style={{maxWidth:"900px"}}/>
                            </aside>
                        </section>
                    </div>

                    <div id="functions" className="guide-part">
                        <h2>Functions</h2>
                        <h4>Arithmetic Functions</h4>
                        <p>Click <a target="blank" href="https://mathjs.org/docs/reference/functions.html#arithmetic-functions">here</a> for supported lists of arithmetic functions.</p>

                        <h4>Aggregate Functions</h4>
                        <ul className="lists" style={{listStyleType:"none"}}>
                            <li>
                                <p><b>avg</b> : Return the average value of the targeted entity</p>
                                <p>pattern: <i>avg([targeted field name], expression)</i> </p>
                            </li>
                            <li>
                                <p><b>sum</b> : Return the sum of the targeted field</p>
                                <p>pattern: <i>sum([targeted field name], expression)</i> </p>
                            </li>
                            <li>
                                <p><b>count</b> : Return the number of rows of the targeted entity</p>
                                <p>pattern: <i>count([targeted field name], expression)</i> </p>
                            </li>
                        </ul>
                        <p>Example: </p>
                        <pre>
                            <code>
                            {`avgLimit = avg(Insurance_Benefit.maxLimitInRupiah, Insurance_Member_Plan.planCode == Insurance_Benefit.planCode);`}<br/>
                            <br/>
                            {`countMember = count(Insurance_Member.memberId, all);`}<br/>
                            <br/>
                            {`sumLimit = sum(Insurance_Benefit.maxLimitInRupiah, Insurance_Member_Plan.planCode == Insurance_Benefit.planCode);`}
                            </code>
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Docs