import { createSlice } from "@reduxjs/toolkit";

import { ExternalVarState, EditExternalVarType } from "./external-data-reducers.types";

const initialState: {[key:string]:ExternalVarState[]} = {};//key is entityId

const externalVarSlice = createSlice({
    name:"external-data",
    initialState,
    reducers:{
        createExternalDataState: (state, action)=>{
            state = action.payload as {[key:string]:ExternalVarState[]};
            return state;
        },
        declareExternalVar: (state, action)=>{
            const newState = action.payload as {[key:string]:ExternalVarState[]};
            state = {...state, ...newState};
            return state;
        },
        addexternalVarItem: (state, action)=>{
            const {entityId, item} = action.payload as EditExternalVarType;
            if(!item) return;
            state[entityId].push(item);
        },
        updateexternalVarItem: (state, action)=>{
            const {entityId, index, item} = action.payload as EditExternalVarType;
            if(index===undefined || !item) return;
            state[entityId][index] = item;
            return state;
        },
        deleteExternalVarEntity: (state, action)=>{
            const entityId = action.payload as string;
            delete state?.[entityId];
        }
    }
})

export const {declareExternalVar, addexternalVarItem, updateexternalVarItem, deleteExternalVarEntity, createExternalDataState} = externalVarSlice.actions;
export const externalVarReducer = externalVarSlice.reducer;

