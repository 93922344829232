import axios, {AxiosError} from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { SelectResponseType, DeclareSelectStatePayload, 
    SelectStateType, SelectStateTypes, ThunkArgsType
 } from "./dataset-selector-reducers.types";

export const selectFetch = createAsyncThunk('select/fetch',async(
    args:ThunkArgsType,thunkAPI):Promise<SelectResponseType | unknown>=>{
    try{
        const response:{data:{result:SelectResponseType[]}} = await axios(args.url);
        return response.data.result;
    }catch(err:any | AxiosError){
        const message = (err?.response?.data?.error_message || err.message || "") as string;
        return thunkAPI.rejectWithValue(message);
    }
});

const initialState: SelectStateTypes = {};

const resetState:SelectStateType = {
    loading:false,
    data:{},
    error:null
}

const selectSlice = createSlice({
    name:"selectData",
    initialState:initialState,
    reducers: {
        createSelectState: (state, action)=>{
            state = action.payload as SelectStateTypes;
            return state;
        },
        declareSelectState:(state,action)=>{
            const {elementID} = action.payload as DeclareSelectStatePayload;
            state[elementID] = resetState;
            return state;
        },
        emptyData:(state,action)=>{
            const {elementID} = action.payload as DeclareSelectStatePayload;
            state[elementID].data = {};
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(selectFetch.pending, (state, {meta})=>{
            const {elementID} = meta.arg;
            state[elementID as string] = {
                loading: true,
                data: {},
                error: null
            }
            return state;
        })
        .addCase(selectFetch.fulfilled, (state, action)=>{
            const {elementID} = action.meta.arg;
            state[elementID as string] = {
                loading: false,
                data: action.payload as SelectResponseType,
                error: null
            }
            return state;
        })
        .addCase(selectFetch.rejected, (state, action)=>{
            const {elementID} = action.meta.arg;
            state[elementID as string] = {
                loading: false,
                data: {},
                error: action.payload as string
            }
            return state;
        })
    }
})

export const {declareSelectState, emptyData, createSelectState} = selectSlice.actions;

export const selectReducers = selectSlice.reducer;