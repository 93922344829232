import { Fragment, useState, FC, useCallback, 
         useMemo, createContext, useContext, useEffect } from "react";
import "../../css/test.css";
import "../../css/data-transform.css";
import "../../css/form-elements.css";
import "../../css/general.css";
import "../../css/modal.css";
import "../../css/navbar.css";
import "../../css/docs.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";

import DatasetForm from "../entity-setup/dataset-form";
import { EntityTypeNames } from "./entity-objects.types.ts";
import Compute from "../entity-setup/compute-form.tsx";
import ExtVarForm from "../entity-setup/external-variable-form.tsx";
import Navbar from "./navbar.tsx";
import { ComputeHelper } from "../utilities/compute-helper.ts";
import { ColumnSetup } from "../fcs/table.tsx";
import { Workspaces } from "./workspace.tsx";

import { ItemOrConnector } from "./entity-objects.types.ts";
import Entities from "./entity-objects.tsx";
import Canvas from "./canvas.tsx";
import { formState } from "../redux/form-state-reducers.types.ts";
import { setFormState } from "../redux/form-state-reducers.ts";
import { layerBasedOnEntTypeName } from "../fcs/index.ts";

//helper classes
import { ConnectorHelper, EntityHelper } from "../utilities/canvas-helper";
import Message, { MessageHelper } from "../fcs/message.tsx";
import Docs from "./docs.tsx";
// import WorkspaceHelper from "../utilities/workspace-helper.ts";

export interface DragContext{
    entityDragFlag:ItemOrConnector;
    setEntityDragFlag:React.Dispatch<React.SetStateAction<ItemOrConnector>>;
    connectorHelper:ConnectorHelper;
    entityHelper:EntityHelper;
    messageHelper: MessageHelper;
}

export interface GeneralContextType{
    entityHelper:EntityHelper;
    connectorHelper:ConnectorHelper;
    computeHelper:ComputeHelper;
    messageHelper:MessageHelper;
}

export const EntityDragContext = createContext<DragContext|null>(null);

export const GeneralContext = createContext<GeneralContextType|null>(null);

const FormLayer0:FC<{dispatch:AppDispatch, connectorHelper:ConnectorHelper}> = ({dispatch, connectorHelper})=>{
    //layer0 targeted for dataset modals, external variable modals, csv modals
    const formStateProps = useSelector<RootState>((state)=>{
        return state.forms[0];
    }) as formState;

    return (
        <Fragment>
            {!(!formStateProps.entityId) && (formStateProps.entityTypeName === EntityTypeNames.externalVariable) && <ExtVarForm entityId={formStateProps.entityId} layer={0} dispatch={dispatch} />}
            {!(!formStateProps.entityId) && (formStateProps.entityTypeName === EntityTypeNames.compute) && <Compute entityId={formStateProps.entityId} layer={0} dispatch={dispatch} computeFieldName=""/>}
            {!(!formStateProps.entityId) && (formStateProps.entityTypeName === EntityTypeNames.dataset) && <DatasetForm entityId={formStateProps.entityId} layer={0} dispatch={dispatch}/>}
        </Fragment>
    )
}

const FormLayer1:FC<{dispatch:AppDispatch}> = ({dispatch})=>{
    //layer0 targeted for dataset modals, external variable modals, csv modals
    const {entityHelper} = useContext(GeneralContext) as GeneralContextType;
    const layer = 1;

    const formStateProps = useSelector<RootState>((state)=>{
        return state.forms[layer];
    }) as formState;

    const closeForm = useCallback(()=>{
        entityHelper.setFormState(layer, "", EntityTypeNames.columnReorderDataset);
    },[layer])

    return (
        <Fragment>
        {!(!formStateProps.entityId) && (formStateProps.entityTypeName === EntityTypeNames.columnReorderDataset) && <ColumnSetup entityId={formStateProps.entityId} layer={1} setFormState={closeForm}/>}
        {/* {!(!formStateProps.entityId) && (formStateProps.entityTypeName === EntityTypeNames.columnReorder) && <ColumnSetup entityId={formStateProps.entityId} layer={1} setFormState={}/>} */}
        </Fragment>
    )
}

const FormLayer2:FC<{dispatch:AppDispatch}> = ({dispatch})=>{
    //layer0 targeted for dataset modals, external variable modals, csv modals
    const formStateProps = useSelector<RootState>((state)=>{
        return state.forms[2];
    }) as formState;

    return (
        <Fragment>
        {!(!formStateProps.entityId) && (formStateProps.entityTypeName === EntityTypeNames.datasetCompute) && <Compute entityId={formStateProps.entityId} layer={2} dispatch={dispatch} computeFieldName={formStateProps.additionalParam1}/>}
        </Fragment>
    )
}

const FormLayer3:FC<{dispatch:AppDispatch}> = ({dispatch})=>{
    //layer0 targeted for dataset modals, external variable modals, csv modals
    const formStateProps = useSelector<RootState>((state)=>{
        return state.forms[3];
    }) as formState;

    return (
        <Fragment>
        {!(!formStateProps.entityId) && (formStateProps.entityTypeName === EntityTypeNames.docs) && <Docs />}
        </Fragment>
    )
}

const DataCompute:FC = ()=>{
    const [entityDragFlag, setEntityDragFlag] = useState<ItemOrConnector>(ItemOrConnector.none);
    const dispatch: AppDispatch = useDispatch();

    const connectorHelper = useMemo(()=>{
        return new ConnectorHelper(dispatch);
    },[]);

    const messageHelper = useMemo(()=>{
        return new MessageHelper(dispatch);
    },[dispatch]);

    const entityHelper = useMemo(()=>{
        return new EntityHelper(connectorHelper, dispatch);
    },[connectorHelper, dispatch]);

    const computeHelper = useMemo(()=>{
        return new ComputeHelper(dispatch, messageHelper);
    },[dispatch, messageHelper]);

    const workspaceItems = useMemo(()=>([
        {title: "Workspace 1", desc: "Insurance premium calculations"},
        {title: "Workspace 2", desc: "Would you like to try?"}
    ]),[]);

    return (
    <Fragment>
    <div className="dt-container">
        <GeneralContext.Provider value={{entityHelper, connectorHelper, computeHelper, messageHelper}}>
            <Navbar />
            <FormLayer0 dispatch={dispatch} connectorHelper={connectorHelper}/>
            <FormLayer1 dispatch={dispatch}/>
            <FormLayer2 dispatch={dispatch}/>
            <FormLayer3 dispatch={dispatch}/>
        </GeneralContext.Provider>
        
        <div className="canvas-container">
            <EntityDragContext.Provider value={{entityDragFlag,setEntityDragFlag,connectorHelper, entityHelper, messageHelper}}>
                <Workspaces workspaceItems={workspaceItems}/>
                <Canvas dispatch={dispatch}/>
                <Entities />
            </EntityDragContext.Provider>
        </div>
        <Message messageHelper={messageHelper} />
    </div>
    </Fragment>
    );
}

export default DataCompute;