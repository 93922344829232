import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { gqlQueryBuilder } from "../utilities/data-functions";
import { ApolloClient, NormalizedCacheObject, ApolloQueryResult, ApolloError } from "@apollo/client";
import axios, {AxiosRequestConfig} from "axios";
import { RootState } from "./store";

import { Data, dataPropsType, GqlParamsType, APIParamsType,
    DatasetResultType, DataReducerState, UpdateDataStateProps, DataReducerStates
 } from "./data-reducers.types";

//async operations
export const retrieveDatasetGql = createAsyncThunk("dataset/fetch",async (args:GqlParamsType, thunkAPI):Promise<DatasetResultType | unknown>=>{
    try{
        const entityId = args?.entityId;
        if(!entityId)throw new Error("Entity Id is undefined");
        const fields = (thunkAPI.getState() as RootState).fields?.[entityId];
        const dataProps = (thunkAPI.getState() as RootState)?.data?.[entityId] as DataReducerState || {gqlQueryName:null};
        const {gqlQueryName} = dataProps;
        if(!gqlQueryName)throw new Error(`Unknown gql resolver: ${gqlQueryName}`);

        const displayedFields = Object.entries(fields).filter(([key,item])=>item.isDisplay && !item.isCompute).map(([key,item])=> key);
        
        const pageIndex = args.pageIndex === undefined || args.pageIndex === null ? 1 : args.pageIndex;
        
        const configs = {
            ...(dataProps.params || {}),
            pageIndex: pageIndex,
            pageSize:dataProps.paging.pageSize || 50,
        };

        const query = gqlQueryBuilder(gqlQueryName, displayedFields, configs);
        
        const response = await args.client.query({
            query
        });

        return {
            data:response.data[gqlQueryName].result,
            paging: {
                pageIndex: response?.data?.[gqlQueryName]?.metadata?.pageIndex || 1,
                totalPage: response?.data?.[gqlQueryName]?.metadata?.totalPage || 1,
                pageSize: response?.data?.[gqlQueryName]?.metadata?.pageSize || 50,
            },
            entityId: args.entityId,
        };
    }catch(e){
        let message;
        if(e instanceof ApolloError){
            message = e.message;
        }else{
            message = "Unknown error, failed to fetch data.";
        }
        return thunkAPI.rejectWithValue({entityId: args.entityId, error: message});
    }
})

export const retrieveDataset = createAsyncThunk("dataset/fetch",async (args:APIParamsType, thunkAPI):Promise<DatasetResultType | unknown>=>{
    try{
        const configs:AxiosRequestConfig = {params: args.params};
        const response = await axios.get(args.url,configs);
        return {
            data:response.data.result,
            entityId: args.entityId,
        };
    }catch(e){
        return thunkAPI.rejectWithValue({entityId: args.entityId, error: e});
    }
})

const initialState:DataReducerStates = {};
const datasetSlice = createSlice({
    name:"dataset",
    initialState: initialState,
    reducers:{
        createDataState: (state, action)=>{
            state = action.payload as DataReducerStates;
            return state;
        },
        declareDataStates: (state,action)=>{
            const newStates = action.payload as DataReducerStates;
            
            for(const [entityId, item] of Object.entries(newStates)){
                state[entityId] = item;
            }
            
            return state;
        },
        setPage: (state,action)=>{
            const {page, entityId} = action.payload as dataPropsType;
            (state[entityId] as DataReducerState).paging.pageIndex = page || 1;
            return state;
        },
        updateState: (state, action)=>{
            const newState = action.payload as UpdateDataStateProps;
            const entityId = newState.entityId as string;
            delete newState.entityId;
            state[entityId] = {...state[entityId],...newState};
        },
        deleteData: (state, action)=>{
            const entityId = action.payload as string;
            delete state?.[entityId];
        },
        resetData: (state, action)=>{
            const entityId = action.payload as string;
            state[entityId].data = [];
            state[entityId].loading = false;
            state[entityId].error = null;
            state[entityId].paging = {
                pageIndex:1,
                pageSize:50,
                totalPage:1,
            }
        }
    },
    extraReducers:((builder)=>{
        builder.addCase(retrieveDataset.pending,(state, {meta})=>{
            const {entityId} = meta.arg;
            (state[entityId] as DataReducerState).loading = true;
            (state[entityId] as DataReducerState).data = [];
            (state[entityId] as DataReducerState).paging = {pageIndex:1, pageSize:50, totalPage:1};
            (state[entityId] as DataReducerState).error = null;
            return state;
        })
        .addCase(retrieveDataset.fulfilled,(state,action)=>{
            const {entityId,data,paging} = action.payload as DatasetResultType;
            (state[entityId] as DataReducerState).loading = false;
            (state[entityId] as DataReducerState).data = data as Data[];
            (state[entityId] as DataReducerState).paging = (paging || {pageIndex:1, pageSize:50, totalPage:1});
            (state[entityId] as DataReducerState).error = null;
            return state;
        })
        .addCase(retrieveDataset.rejected,(state,action)=>{
            const {entityId,data} = action.payload as DatasetResultType;
            (state[entityId] as DataReducerState).loading = false;
            (state[entityId] as DataReducerState).data = [];
            (state[entityId] as DataReducerState).paging = {pageIndex:1, pageSize:50, totalPage:1};
            (state[entityId] as DataReducerState).error = "";
            return state;
        })
    })
})

export const {declareDataStates,setPage, updateState, deleteData, resetData, createDataState} = datasetSlice.actions;

export const dataReducer = datasetSlice.reducer;