import { AppDispatch } from "../redux/store"
import { setConnectorToCanvas,ConnectorState, ConnectorStates, connectorClicked, setMultipleConnectorToCanvas, addEntityToCanvas, clearSelections, updateCanvasEntity, deleteCanvas, deleteConnector } from "../redux/canvas-reducers";
import { v4 as uuid } from "uuid";
import { layerBasedOnEntTypeName } from "../fcs";
import { RootState } from "../redux/store";
import { createSelector } from "reselect";
import { setNewSort, addMultipleEntityFields, deleteField } from "../redux/field-reducers";
import { declareDataStates, updateState, deleteData } from "../redux/data-reducers";
import { addReferences, deleteReference, deleteSubReference} from "../redux/reference-reducers";
import { setFormState } from "../redux/form-state-reducers";
import { deleteExternalVarEntity } from "../redux/external-data-reducers";
import { deleteCompute } from "../redux/compute-reducers";


import { FieldReducerProps } from "../redux/field-reducers.types";
import { DataReducerStates, UpdateDataStateProps } from "../redux/data-reducers.types";
import { DatasetsCallerProps } from "../redux/dataset-selector-reducers.types";
import { Relations } from "../redux/reference-reducers.types";
import { EntityTypeNames } from "../canvas/entity-objects.types";
import { EntitiesState, PosTypeEnum, ConnectorOffsets, DropPosNode, connectorPos, UpdateCanvasEntityProps } from "../redux/canvas-reducers.types";
import { CollectionResponse } from "./canvas-helper.types";

export type GetEntDimension = {
    entMinWidth:number,
    entMinHeight:number,
    entPadding:number
}

export type ProperPos = {
    posX:number,
    posY:number
}
export class CanvasHelper {
    private entMinWidth = 0;
    private entMinHeight = 0;
    private entPadding = 0;

    constructor(){
        let style = getComputedStyle(document.body);
        this.entMinWidth = Number(style.getPropertyValue('--ent-min-width').replace(/px|rem|em/g,''));
        this.entMinHeight = Number(style.getPropertyValue('--ent-min-height').replace(/px|rem|em/g,''));
        this.entPadding = Number(style.getPropertyValue("--ent-item-padding").replace(/px|rem|em/g,''));
    }

    getEntDimension():GetEntDimension{
        return {
            entMinWidth:isNaN(this.entMinWidth)? 0 : this.entMinWidth,
            entMinHeight:isNaN(this.entMinHeight)? 0 : this.entMinHeight,
            entPadding:isNaN(this.entPadding)? 0 : this.entPadding
        }
    }

    getProperPos(clientX:number,clientY:number):ProperPos{
        const {entMinWidth, entMinHeight, entPadding}:GetEntDimension = this.getEntDimension();
        const canvasObj = document.getElementById("canvas");
        const offsetX = canvasObj?.offsetLeft || 0;
        const offsetY = canvasObj?.offsetTop || 0;
        const centerX = Math.floor((entMinWidth + (entPadding * 2))/2);
        const centerY = Math.floor((entMinHeight + (entPadding * 2))/2);
        return {
            posX: clientX - (offsetX + entPadding),
            posY: clientY - (offsetY + entPadding)
        }
    }
}

export const getCssVariable = (varName:string):string=>{
    let style = getComputedStyle(document.body);
    const val = style.getPropertyValue(varName).replace(/px|rem|em/g,'');
    return val;
}

export class ConnectorHelper {
    private dispatch:AppDispatch;
    private connInitialWidth:number;
    private sectionGap:number;
    private posTypeCheck:string[] = ["top","left","bottom","right"];
    private connectorColor:string;
    private referencedConns:{connectorID:string, posType:PosTypeEnum, stationedEntityID:string, stationedPosType:PosTypeEnum, stationedX:number, stationedY:number, node:1|2}[];
    constructor(dispatch:AppDispatch){
        this.dispatch = dispatch;
        // this.connInitialWidth = 159;
        let style = getComputedStyle(document.body);
        this.sectionGap = Number(style.getPropertyValue('--ent-item-padding').replace(/px|rem|em/g,'') || "0");
        this.connInitialWidth = Number(style.getPropertyValue('--connector-line-min-width').replace(/px|rem|em/g,'') || "0");
        this.connectorColor = style.getPropertyValue('--connector-color');
        this.referencedConns = [];
    }

    getPos(offsets:ConnectorOffsets, posType:PosTypeEnum):DropPosNode{
        let pos = {x:0,y:0}, rotation:number = 0;

        switch(posType){
            case PosTypeEnum.top:
                pos = {
                    x: offsets.offsetLeft + (offsets.offsetWidth / 2),
                    y: offsets.offsetTop + (this.sectionGap) - 3,
                };
                break;
            case PosTypeEnum.bottom:
                pos = {
                    x: offsets.offsetLeft + (offsets.offsetWidth / 2),
                    y: offsets.offsetTop + (offsets.offsetHeight - this.sectionGap) - 6,
                };
                break;
            case PosTypeEnum.left:
                pos = {
                    x: (offsets.offsetLeft + this.sectionGap) + 3,
                    y: offsets.offsetTop + (offsets.offsetHeight / 2) - 5,
                };
                break;
            case PosTypeEnum.right:
                pos = {
                    x: offsets.offsetLeft + (offsets.offsetWidth - this.sectionGap)- 3,
                    y: offsets.offsetTop + (offsets.offsetHeight / 2) - 5,
                };
                break;
            default:
                pos = {
                    x: 0,
                    y: 0,
                };
                break;
        }

        return pos;
    }

    calcLineAngle(x1:number,y1:number,x2:number,y2:number):connectorPos{
        // Calculate the distance
        var distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

        // Calculate the angle
        var angle = Math.atan2(y2 - y1, x2 - x1);
        var angleDeg = angle * (180 / Math.PI);

        // Set the width, position, and rotation of the line
        return {
            width: distance,
            top: y1,
            left: x1,
            rotation: angleDeg,
        }
    }

    setReference(entityId1:string, entityId2:string, entityTypeName1:EntityTypeNames, entityTypeName2:EntityTypeNames, connectorId:string){
        
        const addRef = (sourceEntityId:string, sourceTypeName:string, targetEntityID:string, targetTypeName:string)=>{
            if((
                // sourceTypeName === EntityTypeNames.compute || 
                sourceTypeName === EntityTypeNames.externalVariable)
                && targetTypeName===EntityTypeNames.dataset) return;

            if(sourceTypeName === EntityTypeNames.externalVariable && targetTypeName === EntityTypeNames.compute) return;
            const relation:{[keys:string]:Relations} = {
                [targetEntityID]:{
                    [sourceEntityId]:{
                        connectorId,
                    }
                }
            }
            this.dispatch(addReferences(relation));
        }

        //in this case there are no context between which entityId is the source or the target so we will add both
        addRef(entityId1, entityTypeName1, entityId2, entityTypeName2);
    
        addRef(entityId2, entityTypeName2, entityId1, entityTypeName1);

    }

    setConnectorLink(entityId:string, targetEntityID:string, sourceOffsets:ConnectorOffsets, targetOffsets:ConnectorOffsets, sourcePosType:PosTypeEnum, targetPosType:PosTypeEnum, connId?:string):string{
        const connectorID = connId || uuid();
        const bothNodePos = {
            entityNodePos1: {...this.getPos(sourceOffsets,sourcePosType),...{posType:sourcePosType}},
            entityNodePos2: {...this.getPos(targetOffsets,targetPosType),...{posType:targetPosType}},
        }

        const {width,top,left,rotation} = this.calcLineAngle(bothNodePos.entityNodePos1.x,
                                                              bothNodePos.entityNodePos1.y,
                                                              bothNodePos.entityNodePos2.x,
                                                              bothNodePos.entityNodePos2.y);
        
        const connectorProps:ConnectorState = {
            entityId1: entityId,
            entityId2: targetEntityID,
            isSelected:false,
            width: width + "px",
            top:`${top}px`,
            left:`${left}px`,
            transform:`rotate(${rotation}deg)`,
            ...bothNodePos
        }

        this.dispatch(setConnectorToCanvas(connectorID,connectorProps));

        return connectorID
    }

    setClicked(id:string){
        this.dispatch(connectorClicked(id));
    }
    
    setAlterConnector(connectors:ConnectorStates, entityId:string, isDrag:boolean, entityElmt?:HTMLDivElement){   
        let connectorElmt:HTMLDivElement;
        
        if(isDrag){// isDrag === true -> entity is being dragged, set all connector's background with referenced entityIds to be blurred
            for(const [connectorID, props] of Object.entries(connectors)){
                connectorElmt = (document.getElementById(`cl-${connectorID}`) as HTMLDivElement);
                if(!connectorElmt) continue;
                if(props.entityId1===entityId){
                    connectorElmt.style.background = `linear-gradient(to left, ${this.connectorColor}, transparent 40%)`;
                    this.referencedConns.push({
                        connectorID, 
                        posType:props.entityNodePos1.posType,
                        node:1,
                        stationedEntityID:props.entityId2 as string,
                        stationedPosType:props.entityNodePos2.posType,
                        stationedX: props.entityNodePos2.x, 
                        stationedY:props.entityNodePos2.y});
                }else if(props.entityId2===entityId){
                    connectorElmt.style.background = `linear-gradient(to right, ${this.connectorColor}, transparent 40%)`;
                    this.referencedConns.push({
                        connectorID, 
                        node:2,
                        posType:props.entityNodePos2.posType,
                        stationedEntityID:props.entityId1 as string,
                        stationedPosType:props.entityNodePos1.posType,
                        stationedX: props.entityNodePos1.x, 
                        stationedY:props.entityNodePos1.y});
                }
            };
        }else{// isDrag === false -> entity is being dropped, set all connector's background with referenced entityIds back to initial
            if(!entityElmt) return;
            const targetOffsets:ConnectorOffsets = {
                offsetTop:entityElmt.offsetTop,
                offsetLeft:entityElmt.offsetLeft,
                offsetWidth:entityElmt.offsetWidth,
                offsetHeight:entityElmt.offsetHeight,
            };
            const chosenConnStates:ConnectorStates = {};
            for(const props of this.referencedConns){
                connectorElmt = (document.getElementById(`cl-${props.connectorID}`) as HTMLDivElement);
                if(!connectorElmt) continue;
                
                const newPos = this.getPos(targetOffsets,props.posType) as DropPosNode;
                const bothNodePos = {
                    entityNodePos1: {
                        x: props.node===1 ? newPos.x : props.stationedX,
                        y: props.node===1 ? newPos.y : props.stationedY,
                        posType: props.node===1 ? props.posType : props.stationedPosType,
                    },
                    entityNodePos2: {
                        x: props.node===2 ? newPos.x : props.stationedX,
                        y: props.node===2 ? newPos.y : props.stationedY,
                        posType: props.node===2 ? props.posType : props.stationedPosType,
                    },
                }
                const {width,top,left,rotation} = this.calcLineAngle(bothNodePos.entityNodePos1.x,
                    bothNodePos.entityNodePos1.y,
                    bothNodePos.entityNodePos2.x,
                    bothNodePos.entityNodePos2.y);

                chosenConnStates[props.connectorID] = {
                        entityId1: props.node===1 ? entityId : props.stationedEntityID,
                        entityId2: props.node===2 ? entityId : props.stationedEntityID,
                        isSelected:false,
                        width: width + "px",
                        top:`${top}px`,
                        left:`${left}px`,
                        transform:`rotate(${rotation}deg)`,
                        ...bothNodePos
                }

                connectorElmt.style.background = this.connectorColor;//set blurred connector to initial background color;
            }
            
            this.dispatch(setMultipleConnectorToCanvas(chosenConnStates));//set multiple connector new position and angle;
            this.referencedConns = [];//reset the referenced connector
        }
    }

    clearSelections(){
        this.dispatch(clearSelections());
    }

    setNoHover(isNoHover:boolean){
        const connectors = document.querySelectorAll(".connector-bg");
        const nodeSection = document.querySelectorAll(".ent-section");
        connectors.forEach(element => {
            if(isNoHover){
                element.classList.add("connector-no-hover");
            }else{
                element.classList.remove("connector-no-hover");
            }
        });

        nodeSection.forEach(element => {
            if(isNoHover){
                element.classList.add("node-no-hover");
            }else{
                element.classList.remove("node-no-hover");
            }
        });
    }

    deleteConnector = (connectorId:string, referenceStates:{[key: string]: Relations})=>{
        for(const [entityId, props] of Object.entries(referenceStates)){
            for(const [refEntityId, refProps] of Object.entries(props)){
                if(refProps?.connectorId === connectorId){
                    this.dispatch(deleteSubReference({entityId,refEntityId}));
                }
            }
        }
        this.dispatch(deleteConnector(connectorId));
    }
}

export class EntityHelper {
    private entBodyMaxWidth:number;
    private entPadding:number;
    private entMinHeight:number;
    private distanceX:number;
    private distanceY:number;
    constructor(private connectorHelper:ConnectorHelper, private dispatch:AppDispatch){
        let style = getComputedStyle(document.body);
        this.entBodyMaxWidth = Number(style.getPropertyValue("--ent-body-max-width").replace(/px|rem|em/g,''));
        this.entPadding = Number(style.getPropertyValue("--ent-item-padding").replace(/px|rem|em/g,''));
        this.entMinHeight = Number(style.getPropertyValue("--ent-min-height").replace(/px|rem|em/g,''));
        this.distanceX = (this.entPadding * 2 + this.entBodyMaxWidth) + 50
        this.distanceY = (this.entPadding * 2 + this.entMinHeight) + 50
    }

    setFormState(layer:number, entityId:string, entityType: EntityTypeNames){
        this.dispatch(setFormState(layer, entityId, entityType));
    }

    generateRelatedEntity(datasetId:string, headerEntityId:string, collections:CollectionResponse, headerX:number, headerY:number, datasetInfo:DatasetsCallerProps, datasetContext:string){
        const canvasProps:EntitiesState = {};
        const layer = layerBasedOnEntTypeName[EntityTypeNames.dataset];
        const offsets:{[key:string]:{x:number, y:number, rowGroupIndex:number}} = {}
        const connectorStates:ConnectorStates = {};
        const connectorMap:{[key:string]:string[]} = {};
        const entityIdMap:{[key:string]:string} = {};
        const entityFields:FieldReducerProps = {};
        const DataStates: DataReducerStates = {};
        const referenceStates: {[key:string]:Relations} = {};

        let rowGroupY:number[] = [0];

        let i = 0, sortIndex=0;

        for(const [APIEntityName, props] of Object.entries(collections)){
            let entityId:string = i===0 ? headerEntityId : uuid();

            entityIdMap[APIEntityName] = entityId;

            entityFields[entityId] = {};
            sortIndex = 0;
            for(const [fieldName,fieldProps] of Object.entries(props.fields)){
                entityFields[entityId][fieldName] = {
                    isPrimaryColumn: fieldProps.isPrimaryColumn,
                    isDisplay:true,
                    isCompute:false,
                    sortIndex
                }
                sortIndex += 1;
            }

            if(props.relations.length > 0){
                connectorMap[APIEntityName as string] = [];
                
                referenceStates[entityId] = {};
                for(const relation of props.relations){
                    //calculate distance based on nearest relation
                    const {x,rowGroupIndex} = offsets[relation.referencedEntity as string];
                    const y = rowGroupY[rowGroupIndex + 1] ? rowGroupY[rowGroupIndex + 1] + this.distanceY : headerY;
                    offsets[APIEntityName as string] = {
                        x: x + this.distanceX,
                        y,
                        rowGroupIndex: rowGroupIndex + 1
                    }
                    if(connectorMap[APIEntityName].findIndex((item)=>item===relation.referencedEntity) === -1){
                        
                        let connectorID:string | undefined;
                        if(relation.isRelationOwner){
                            connectorID = uuid();
                            connectorMap[APIEntityName].push(relation.referencedEntity as string);
                            connectorStates[connectorID] = {
                                entityId1:entityIdMap[relation.referencedEntity as string],
                                entityNodePos1:{x:0,y:0,posType:PosTypeEnum.right},
                                entityId2:entityId,
                                entityNodePos2:{x:0,y:0,posType:PosTypeEnum.left},
                                isSelected:false,
                                width:undefined,
                                top:undefined,
                                left:undefined,
                                transform:undefined,
                                noDelete:true,
                            }
                        }

                        referenceStates[entityId][entityIdMap[relation.referencedEntity as string]] = {
                            ...relation,
                            connectorId: connectorID,
                        }
                    }
                    
                    rowGroupY[rowGroupIndex + 1] = y;
                }
            }else{
                const y = rowGroupY[0] ? rowGroupY[0] + this.distanceY : headerY;
                offsets[APIEntityName] = {
                    x:headerX,
                    y,
                    rowGroupIndex:0
                }
                rowGroupY[0] = y;
            }

            let backendType:{
                apiUrl?:string,
                params?:{[key:string]:string|number},
                gqlQueryName?:string,
            }={};
            if(datasetInfo.apiUrl){
                backendType = {
                    apiUrl:datasetInfo.apiUrl,
                    params:datasetInfo.params,
                };
            }else if(datasetInfo.gqlQueryName){
                backendType = {
                    params:datasetInfo.params,
                    gqlQueryName:datasetInfo.gqlQueryName,
                }
            }

            DataStates[entityId] = {
                datasetId,
                apiEntityName: APIEntityName,
                params:datasetInfo.params,
                gqlQueryName: APIEntityName + "s",
                loading:false,
                data:[],
                error:null,
                datasetContext,
                headerEntityId: entityId === headerEntityId ? undefined : headerEntityId,
                paging:{
                    pageIndex:1,
                    pageSize:50,
                    totalPage:1
                },
            }

            canvasProps[entityId] = {
                entityTypeName:EntityTypeNames.dataset,
                faClass:"fa-solid fa-table entities-cont",//font-awesome class + custom class
                entityName:APIEntityName,
                layer:layer,
                posX:offsets[APIEntityName].x,
                posY:offsets[APIEntityName].y,
                offsetWidth:0,
                offsetHeight:0,
            }
            i++;
        }
        this.dispatch(addEntityToCanvas(canvasProps));
        this.dispatch(setMultipleConnectorToCanvas(connectorStates));
        this.dispatch(addMultipleEntityFields(entityFields));
        this.dispatch(declareDataStates(DataStates));
        this.dispatch(addReferences(referenceStates));
    }

    private getFieldState = (state:RootState):FieldReducerProps=>state.fields;

    sortedFieldSelector = (entityId:string)=>{
        const selector = createSelector(
            [this.getFieldState],
            (entityFields)=>{
                if(!entityFields[entityId]) return [];
                const sortedFields = Object.entries(entityFields[entityId]).map(([fieldName, fieldProps])=>({
                    fieldName,...fieldProps
                }));
                return sortedFields.sort((a, b) => a.sortIndex - b.sortIndex);
            }
        )
        return selector;
    }

    private getDataState = (state:RootState):DataReducerStates=>state.data;

    subDatasetSelector = (entityId:string)=>{
        const selector = createSelector(
            [this.getDataState],
            (data)=>{
                return data?.[entityId]?.headerEntityId ? true : false;
            }
        )
        return selector;
    }

    updateDataset = ((props:UpdateDataStateProps)=>{
        this.dispatch(updateState(props))
    });

    setNewSort = (entityId:string, fieldName:string, newIndex:number)=>{
        this.dispatch(setNewSort({entityId,fieldName,newIndex}));
    }

    updateCanvas = ((props: UpdateCanvasEntityProps)=>{
        this.dispatch(updateCanvasEntity(props));
    });

    deleteReferencesByEntity = (currentEntityId:string, referenceStates:{[key: string]: Relations})=>{
        //delete all data referenced to targeted entityId;
        for(const [entityId, props] of Object.entries(referenceStates)){
            for(const [refEntityId, refProps] of Object.entries(props)){
                if(refEntityId === currentEntityId || refProps.referencedEntity === currentEntityId){
                    this.dispatch(deleteSubReference({entityId,refEntityId}));
                }
            }
        }
        this.dispatch(deleteReference(currentEntityId));
    }   

    deleteConnectorsByEntity = (currentEntityId:string, connectorStates: ConnectorStates)=>{
        //delete all connectors tied to targeted entityId
        for(const [connectorId, props] of Object.entries(connectorStates)){
            if(props.entityId1 === currentEntityId || props.entityId2 === currentEntityId){
                this.dispatch(deleteConnector(connectorId));
            }
        }
    }

    private deleteDatasets = (entityId: string, referenceStates: {[key: string]: Relations}, connectorStates: ConnectorStates)=>{
        this.deleteReferencesByEntity(entityId, referenceStates);
        this.deleteConnectorsByEntity(entityId, connectorStates);
        this.dispatch(deleteData(entityId));
        this.dispatch(deleteField(entityId));
        this.dispatch(deleteCompute(entityId));
        this.dispatch(deleteCanvas(entityId));
    }

    deleteEntity = (currentEntityId:string, entityTypeName:EntityTypeNames, store:RootState)=>{
        
        if(entityTypeName === EntityTypeNames.compute){
            this.deleteReferencesByEntity(currentEntityId, store.references);
            this.deleteConnectorsByEntity(currentEntityId, store.connectors);
            this.dispatch(deleteCompute(currentEntityId));
            this.dispatch(deleteCanvas(currentEntityId));
        }else if(entityTypeName === EntityTypeNames.externalVariable){
            this.deleteReferencesByEntity(currentEntityId, store.references);
            this.deleteConnectorsByEntity(currentEntityId, store.connectors);
            this.dispatch(deleteExternalVarEntity(currentEntityId));
            this.dispatch(deleteCanvas(currentEntityId));
        }else if(entityTypeName ===  EntityTypeNames.dataset){
            //delete subs
            for(const [entityId, props] of Object.entries(store.data)){
                if(props.headerEntityId === currentEntityId){
                    this.deleteDatasets(entityId, store.references, store.connectors)
                }
            }
            //finally delete the header
            this.deleteDatasets(currentEntityId, store.references, store.connectors);
        }
    }
}

