import { FC, useRef, useCallback, useState, Fragment, useMemo, useContext } from "react";
import { AppDispatch } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "../taskbar-buttons";
import { SingleFieldModal } from "../canvas/modal";
import { setFormState } from "../redux/form-state-reducers";
import { updateFieldProps, addComputeField } from "../redux/field-reducers";
import { GeneralContext, GeneralContextType } from "../canvas/main";
import { RootState } from "../redux/store";

import { CodeProps } from "./code-editor.types";

import { ElementPos, EntityTypeNames } from "../canvas/entity-objects.types";
import { SortedField, ItemSortProps } from "./column-reorder.types";
import { AddComputeFieldProps } from "../redux/field-reducers.types";

const ItemSort:FC<ItemSortProps&{entityId:string,dispatch:AppDispatch}> = ({fieldName, index, containerID, sortedFields, entityId,dispatch})=>{
    const Item = useRef<ElementPos | null>(null);
    const isDisplay = useSelector((state:RootState)=>state.fields?.[entityId]?.[fieldName]?.isDisplay);
    const {entityHelper} = useContext(GeneralContext) as GeneralContextType;

    const resetMarginTop = useCallback(()=>{
        const container = document.getElementById(containerID);
        const nonDraggedItems = (container ? [...container.querySelectorAll<Element>(".item-sort:not(.dragging)")] : []) as HTMLElement[];

        for(const nonDragged of nonDraggedItems){
            nonDragged.style.marginTop = "0";
        }
    },[containerID]);

    const processSort = useCallback((clientY:number):number=>{
        if(sortedFields.length === 1) return -1;
        const container = document.getElementById(containerID);
        const nonDraggedItems = (container ? [...container.querySelectorAll<Element>(".item-sort:not(.dragging)")] : []) as HTMLElement[];
        const containerOffsetTop = container?.offsetTop || 0;

        let foundFirst:boolean = false;
        let i:number = 0, foundIndex = 0;
        for(const nonDragged of nonDraggedItems){
            let posY = clientY - containerOffsetTop;
            if(posY <= nonDragged.offsetTop + nonDragged.offsetHeight && !foundFirst){
                
                foundFirst = true;
                foundIndex = i;
                
                nonDragged.style.marginTop = `${nonDragged.offsetHeight}px`;
                i++;
                continue;
            }
            nonDragged.style.marginTop = "0";

            i++;
        }
        return foundFirst ? foundIndex : -1;
    },[containerID,sortedFields])

    const mouseMove = useCallback((e:MouseEvent | React.MouseEvent) => {
        if(!Item.current){
            return;
        };
        // Item.current.classList.remove("insert-animation");
        let newY = (Item.current?.startY || 0) - e.clientY;
        Item.current.startY = e.clientY;
        Item.current.style.top = `${Item.current.offsetTop - newY}px`;
        Item.current.foundIndex = processSort(e.clientY);
      },[processSort]);

    const mouseUp = useCallback((e:MouseEvent | React.MouseEvent)=>{
        if(!Item.current) return;
        Item.current.classList.remove("dragging");
        Item.current.style.top = "unset";
        
        document.removeEventListener("mousemove", mouseMove);
        document.removeEventListener("mouseup",mouseUp);
        resetMarginTop();

        const newIndex = (Item.current.foundIndex === -1 ? sortedFields.length - 1 : Item.current.foundIndex) || 0;
        entityHelper.setNewSort(entityId,fieldName,newIndex);
    },[mouseMove, processSort, sortedFields, index, entityId]);

    const mouseDown = useCallback((e:MouseEvent|React.MouseEvent) => {
        if(!Item.current) return;
        const containerOffsetY = Item.current.offsetTop;
        Item.current?.classList.add("dragging");
        Item.current.style.top = containerOffsetY + "px";
        Item.current.startY = e.clientY;
        document.addEventListener("mousemove",mouseMove);
        document.addEventListener("mouseup",mouseUp);
    },[mouseMove,mouseUp]);

    return(
        <div ref={Item} style={{marginTop:"0"}} className={`item-sort non-selectable`}>
            <i title={"Drag To Sort"} className={`fa-solid fa-grip-vertical`} onMouseDown={mouseDown}/>
            
            <input title="Display column?" id={fieldName} type="checkbox" checked={isDisplay} 
                   onChange={()=>{
                                    dispatch(updateFieldProps({entityId,fieldName,isDisplay:!isDisplay}))
                                 }}></input> 
            {/* {fields[index].isCompute ? <i title={"This is a compute field"} className={`fa-solid fa-calculator`}/> : null} */}
            <label title={sortedFields[index].isCompute ? "This is a compute field" : ""} className={!isDisplay ? "item-not-displayed" : "" } htmlFor={fieldName}>
                {fieldName}
            </label>
            {sortedFields[index].isCompute ? <i title={"Click to edit the formula"} className={`fa-solid fa-calculator compute-field`} onClick={()=>{dispatch(setFormState(2,entityId,EntityTypeNames.datasetCompute,fieldName))}}/> : null}
        </div>
    );
}

const ColumnReorder:FC<CodeProps> = ({entityId,entityName})=>{
    const {entityHelper} = useContext(GeneralContext) as GeneralContextType;
    const dispatch = useDispatch();
    const fields:SortedField[] = useSelector(entityHelper.sortedFieldSelector(entityId));
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const containerStyle = {
        height:"95%"
    }

    const id = useMemo(()=>"cr-" + entityName, [entityName]);

    const addCompute = useCallback((fieldName:string)=>{
        const params:AddComputeFieldProps = {
            entityId,
            fieldName,
        };
        // dispatch(saveCode({entityId,fieldName,code:"",returnType:ReturnType.number}));
        dispatch(addComputeField(params));
        setIsShowEditModal(false);
    },[dispatch, entityId]);

    return (
        <Fragment>
            <div className="table-fc" style={{borderTopLeftRadius:"0"}}>
                <div className="table-toolbar">
                    <Add title="Add Compute Field" additionalClass="table-toolbar-btn" onClick={()=>{
                        setIsShowEditModal(true);
                    }}/>
                </div>
                <div className="table-container">
                    <div id={id} className="item-sort-container" style={containerStyle}>
                        {fields.map((field,index)=><ItemSort key={field.fieldName} fieldName={field.fieldName} 
                                                            index={index} 
                                                            containerID={id} 
                                                            entityId={entityId}
                                                            dispatch={dispatch}
                                                            sortedFields={fields}/>)}
                    </div>
                </div>
            </div>
            {isShowEditModal && <SingleFieldModal labelText="Compute Field Name" setValue={addCompute} setDisplay={setIsShowEditModal}/>}
        </Fragment>
    )
}

export default ColumnReorder;