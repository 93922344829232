import CodeEditor from "./code-editor";
import ColumnReorder from "./column-reorder";
import ContextField from "./context-field";
import ElementError from "./element-error";
import ExternalVarTable from "./external-var-table";
import SelectField from "./select-field";
import Tab from "./tab";
import Table from "./table";
import Loader from "./loading";

import { EntityTypeNames } from "../canvas/entity-objects.types";

export type LayerBasedOnEntTypeName = {
    [key in EntityTypeNames]: number
}

export const layerBasedOnEntTypeName:LayerBasedOnEntTypeName = {
    [EntityTypeNames.compute]:0,
    [EntityTypeNames.datasetCompute]:2,
    [EntityTypeNames.docs]:3,
    [EntityTypeNames.dataset]:0,
    [EntityTypeNames.externalVariable]:0,
    [EntityTypeNames.csv]:0,
    [EntityTypeNames.columnReorderDataset]:1,
    [EntityTypeNames.columnReorder]:1,
    [EntityTypeNames.connector]:-1,
};

export {
    CodeEditor,
    ColumnReorder,
    ContextField,
    ElementError,
    ExternalVarTable,
    SelectField,
    Tab,
    Table,
    Loader,
    // MessageBox,
}