import { FC, useEffect, Fragment, useCallback } from "react";
import { setMessage, closeMessage } from "../redux/message-reducer";
import { RootState, AppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import { Error, Warning, Info } from "../taskbar-buttons";

import { MessageType, MessageState } from "../redux/message-reducer.types";

export class MessageHelper{
    public handleConfirm:Function | undefined;
    public handleCancel:Function | undefined;
    constructor(private dispatch:AppDispatch){}
    
    confirm(message:string, handleConfirm:Function | undefined = undefined, handleCancel:Function | undefined = undefined){
        this.handleConfirm = handleConfirm;
        this.handleCancel = handleCancel;
        this.dispatch(setMessage({message, messageType:MessageType.confirm}));
    }

    fadingMsg(message:string){
        this.dispatch(setMessage({message, messageType:MessageType.messageFading}));
    }

    error(message:string){
        this.dispatch(setMessage({message, messageType:MessageType.error}));
    }

    warning(message:string){
        this.dispatch(setMessage({message, messageType:MessageType.warning}));
    }

    info(message:string){
        this.dispatch(setMessage({message, messageType:MessageType.info}));
    }

    closeMessage(){
        this.dispatch(closeMessage(''));
    }
}

const MessageError:FC<{message:string, messageHelper:MessageHelper}> = ({
    message, 
    messageHelper,
})=>{

    return (
        <div className="canvas-modal" style={{zIndex:"1000"}}>
            
            <div className="msgbox-body">
                <Error title="This is an error message" additionalClass=""/>
                <span>{message}</span>
                <div className="msg-btn">
                    <button className="module-button" onClick={()=>{
                        messageHelper.closeMessage();
                    }}>Ok</button>
                </div>
            </div>
            
        </div>
    )
}

const MessageWarning:FC<{message:string, messageHelper:MessageHelper}> = ({
    message, 
    messageHelper,
})=>{

    return (
        <div className="canvas-modal" style={{zIndex:"1000"}}>
            <div className="msgbox-body">
                <Warning title="This is a warning message" additionalClass=""/>
                <span>{message}</span>
                <div className="msg-btn">
                    <button className="module-button" onClick={()=>{
                        messageHelper.closeMessage();
                    }}>Ok</button>
                </div>
            </div>
        </div>
    )
}

const MessageInfo:FC<{message:string, messageHelper:MessageHelper}> = ({
    message, 
    messageHelper,
})=>{

    return (
        <div className="canvas-modal" style={{zIndex:"1000"}}>
            <div className="msgbox-body">
                <Info title="This is an infomation" additionalClass=""/>
                <span>{message}</span>
                <div className="msg-btn">
                    <button className="module-button" onClick={()=>{
                        messageHelper.closeMessage();
                    }}>Ok</button>
                </div>
            </div>
        </div>
    )
}

const Confirm:FC<{message:string, messageHelper:MessageHelper}> = ({
    message, 
    messageHelper,
})=>{

    const handleConfirm = useCallback(()=>{
        if(typeof messageHelper?.handleConfirm === "function"){
            messageHelper.handleConfirm();
        }
    }, [messageHelper, message]);

    const handleCancel = useCallback(()=>{
        if(typeof messageHelper?.handleCancel === "function"){
            messageHelper.handleCancel();
        }
    }, [messageHelper, message]);

    return (
        <div className="canvas-modal" style={{zIndex:"1000"}}>
            <div className="msgbox-body">
                <span>{message}</span>
                <div className="msg-btn">
                    <button className="module-button" style={{backgroundColor:"gray"}} onClick={()=>{
                        if(typeof handleCancel === "function"){
                            handleCancel();
                        }
                        messageHelper.closeMessage();
                    }}>Cancel</button>

                    <button className="module-button" style={{backgroundColor:"#1CC850FF"}} onClick={()=>{
                        if(typeof handleConfirm === "function"){
                            handleConfirm();
                        }
                        messageHelper.closeMessage();
                    }}>Confirm</button>
                </div>
            </div>
            
        </div>
    )
}

const FadingMessage:FC<{message:string}> = ({message})=>{
    return (<Fragment>
        <div id="fd-" className={"fading-message"} style={{zIndex:"1000"}}>
            {message}
        </div>
    </Fragment>)
}

const Message:FC<{messageHelper:MessageHelper}>  = ({messageHelper})=>{
    const {message, messageType} = useSelector<RootState>((state)=>{
        return state.message;
    }) as MessageState;

    useEffect(()=>{
        if(!message) return;
        else if(message && messageType===MessageType.messageFading){
            setTimeout(()=>{
                messageHelper.closeMessage();
            }, 1000);
        }
    }, [message, messageHelper, messageType]);

    return (
        <Fragment>
        {(message !== "" && messageType === MessageType.confirm) ? <Confirm message={message} messageHelper={messageHelper} /> : null}

        {(message !== "" && messageType === MessageType.messageFading) ? <FadingMessage message={message}/> : null}

        {(message !== "" && messageType === MessageType.error) ? <MessageError message={message} messageHelper={messageHelper}/> : null}
        
        {(message !== "" && messageType === MessageType.warning) ? <MessageWarning message={message} messageHelper={messageHelper}/> : null}

        {(message !== "" && messageType === MessageType.info) ? <MessageInfo message={message} messageHelper={messageHelper}/> : null}
        </Fragment>
    )
}

export default Message;

// export default MessageBox