export const enum MessageType{
    messageFading,
    confirm,
    error,
    warning,
    info,
}

export type MessageState = {
    message:string,
    messageType:MessageType
}