import { createSlice } from "@reduxjs/toolkit";
import { UpdateFieldProps, Fields, FieldReducerProps, AddFieldProps, AddComputeFieldProps } from "./field-reducers.types";

const initialState:FieldReducerProps = {};

const reviewFieldName = (fields: Fields, newName:string):string=>{
    let list = Object.keys(fields);
    let incrementFlag = 0;
    let foundIndex = 0;

    while(foundIndex >= 0){
        foundIndex = list.findIndex((fieldName)=>fieldName === newName);
        if(foundIndex !== -1){
            incrementFlag += 1;
            newName = `${newName}_${incrementFlag}`;
        }else break;
    }
    //replace spaces into _
    newName = newName.replace(/\s/g, '_');
    return newName;
}

const fieldSlice = createSlice({
    name:"dataset-fields",
    initialState,
    reducers:{
        createFieldState: (state, action)=>{
            state = action.payload as FieldReducerProps;
            return state;
        },
        addField:(state,action)=>{
            const {entityId, fields} = action.payload as AddFieldProps;
            state[entityId] = fields;
            return state;
        },
        addMultipleEntityFields:(state,action)=>{
            const entities = action.payload as FieldReducerProps;
            for(const [entityId,fields] of Object.entries(entities)){
                state[entityId] = fields;
            }
            return state;
        },
        setNewSort:(state,action)=>{
            const {entityId, fieldName, newIndex} = action.payload;
            const oldIndex:number | undefined = state[entityId]?.[fieldName].sortIndex;
            if(oldIndex !== undefined){
                for(const [fieldNameKey, item] of Object.entries(state[entityId])){
                    if(item.sortIndex <= newIndex && item.sortIndex > oldIndex) item.sortIndex -= 1;
                    if(item.sortIndex <= newIndex && item.sortIndex < oldIndex) item.sortIndex += 1;
                    if(fieldNameKey === fieldName) item.sortIndex = newIndex;
                }
                return state;
            }
        },
        updateFieldProps:(state,action)=>{
            const props = action.payload as UpdateFieldProps;
            const entityId = props.entityId as string;
            const fieldName = props.fieldName as string;
            delete props.entityId;
            delete props.fieldName;
            state[entityId][fieldName] = {...state[entityId][fieldName], ...props};
        },
        addComputeField:(state,action)=>{
            const {entityId, fieldName} = action.payload as AddComputeFieldProps;
            if(!state[entityId]) state[entityId] = {};
            const reviewedFieldName = reviewFieldName(state[entityId], fieldName);
            state[entityId][reviewedFieldName] = {
                isCompute:true,
                isDisplay:true,
                sortIndex:Object.keys(state[entityId]).length
            }
        },
        deleteField:(state,action)=>{
            const entityId = action.payload as string;
            delete state?.[entityId];
        }
    }
});

export const {addField, addMultipleEntityFields, setNewSort, updateFieldProps, addComputeField, deleteField, createFieldState} = fieldSlice.actions;

export const fieldReducer = fieldSlice.reducer;
