import { FC, useEffect, Fragment, useState } from "react";
import { AppDispatch, RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { selectFetch, emptyData } from "../redux/dataset-selector-reducers";
import ElementError from "./element-error";
import { EntitySelectFieldInfo } from "./select-field.types";

import { SelectStateType } from "../redux/dataset-selector-reducers.types";

const SelectField:FC<EntitySelectFieldInfo> = ({fieldName, labelWidth,fieldTitle,fieldValue,buttonProps,onChange,width, url, options})=>{
    const dispatch:AppDispatch = useDispatch();
    const [isFetched,setFetched] = useState(false);

    const selectState = useSelector<RootState>((state)=>state.selectElements[fieldName || ""]) as SelectStateType;

    const selectStyle={
        width
    }

    const labelStyle={
        width:labelWidth || undefined,
    }

    useEffect(()=>{
        if(url){
            setFetched(true);
            dispatch(selectFetch({elementID: fieldName, url:url}));
        }

        return(()=>{
            dispatch(emptyData({elementID: fieldName}));
        })
    },[dispatch])

    return (
        <Fragment>
        <div className="field-container">
            <span style={labelStyle}>{fieldTitle}</span>
            <select id={fieldName} style={selectStyle} defaultValue={fieldValue} onChange={onChange}>
                <option></option>
                {selectState?.loading && <option value="">Loading Data...</option>}
                {!options && selectState !== undefined && !selectState.error && !selectState.loading && Object.entries(selectState.data || {}).map(([key,item], index)=><option key={index} value={key}>{item.textContent}</option>)}
                {Array.isArray(options) && Object.entries(options || []).map(([key,textContent], index)=><option key={index} value={key}>{textContent}</option>)}
            </select>
            {buttonProps}
        </div>
        {selectState && selectState.error !== null && <ElementError message={selectState.error}/>}
        </Fragment>
    )
}

export default SelectField;