import { FC, useMemo } from "react";
import { EntityFieldInfo } from "./context-field.types";

const ContextField:FC<EntityFieldInfo> = ({fieldTitle,buttonProps,fieldValue,labelWidth})=>{

    const labelWidthStyle = useMemo(()=>{
        return labelWidth ? {width:labelWidth} : {};
    },[labelWidth])

    return (
        <div className="field-container">
            <span style={labelWidthStyle} key="s-1">{fieldTitle}</span>
            <span key="s-2">{fieldValue}</span>
            {buttonProps}
        </div>
    )
}

export default ContextField